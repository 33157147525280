// Require Froala Editor js file.
require("froala-editor/js/froala_editor.pkgd.min.js");

// Require Froala Editor css files.
import "froala-editor/js/third_party/font_awesome.min";

require("froala-editor/css/froala_editor.pkgd.min.css");
require("froala-editor/css/froala_style.min.css");
import "froala-editor/js/plugins/align.min";
import "froala-editor/js/plugins/colors.min";
import "froala-editor/js/plugins/draggable.min";
import "froala-editor/js/plugins/font_family.min";
import "froala-editor/js/plugins/font_size.min";
import "froala-editor/js/plugins/fullscreen.min";
import "froala-editor/js/plugins/image.min";
import "froala-editor/js/plugins/image_manager.min";
import "froala-editor/js/plugins/inline_style.min";
import "froala-editor/js/plugins/inline_style.min";
import "froala-editor/js/plugins/link.min";
import "froala-editor/js/plugins/lists.min";
import "froala-editor/js/plugins/paragraph_format.min";
import "froala-editor/js/plugins/paragraph_style.min";
import "froala-editor/js/plugins/quick_insert.min";
import "froala-editor/js/plugins/quote.min";
import "froala-editor/js/plugins/url.min";
import "froala-editor/js/plugins/video.min";
import "froala-editor/js/plugins/code_view.min";
// import "froala-editor/js/third_party/embedly.min";
import Vue from "vue";
// Import and use Vue Froala lib.
import VueFroala from "vue-froala-wysiwyg";
Vue.use(VueFroala);
