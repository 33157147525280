<template>
  <div class="navbar" :class="[site]">
    <div class="navbar-inner" ref="inner">
      <div class="navbar-left">
        <router-link
          :to="homePage"
          class="navbar-logo"
          v-if="currentsite === 'buyers'"
          @click.native="resetVideoSrc"
        >
          <img
            v-if="theme === 'dark'"
            class="desktop-logo"
            src="@/images/logos/IncredEvent-Logo-Dark.webp"
            alt="incredevent.com"
          />
          <img
            v-else
            class="desktop-logo"
            src="@/images/logos/IncredEvent-Logo.webp"
            alt="incredevent.com"
          />

          <img
            src="@/images/logos/IncredEvent-Mobile-Dark.webp"
            alt=""
            srcset=""
            class="mobile-logo"
          />
        </router-link>
        <router-link :to="homePage + 'dashboard'" v-else class="navbar-logo">
          <img
            v-if="theme === 'dark'"
            class="desktop-logo"
            src="@/images/logos/IncredEvent-Logo-Dark.webp"
            alt="incredevent.com"
          />
          <img
            v-else
            class="desktop-logo"
            src="@/images/logos/IncredEvent-Logo.webp"
            alt="incredevent.com"
          />
          <img
            src="@/images/logos/IncredEvent-Mobile-Dark.webp"
            alt=""
            srcset=""
            class="mobile-logo"
          />
        </router-link>

        <div v-if="title || subtitle" class="header-text">
          <div class="title">{{ title }}</div>
          <div class="sub-title">{{ subtitle }}</div>
        </div>
      </div>

      <!-- :class="{ active }" -->
      <div class="navbar-right">
        <div class="links">
          <template v-for="(item, index) in items">
            <router-link
              v-if="item.link"
              :to="item.link"
              :key="index"
              @click.native="resetVideoSrc"
            >
              {{ item.name }}
            </router-link>
          </template>
        </div>
      </div>
    </div>
    <!-- <div class="navbar-bottom">
      
      <div class="img-box">
        <router-link to="/my-tickets" v-if="isAuthenticated">
          <div class="tote-wrapper">
            <span class="tote-number">{{
              displayOver100(user.upcomingTickets)
            }}</span>
            <img src="/img/TT-Close.png" alt="myTote" class="img tote" />
          </div>

        </router-link>
        <router-link :to="login" v-else>
          <div class="tote-wrapper">
            <span class="tote-number">{{
              displayOver100(user.upcomingTickets)
            }}</span>
            <img src="/img/TT-Close.png" alt="myTote" class="img tote" />
          </div>

        </router-link>
      </div>

      <div class="img-box">
        <span class="number" v-if="notificationState.unreadCount">
          {{ displayOver100(notificationState.unreadCount) }}
        </span>
        <router-link to="/notifications">
          <img
            src="@/images/icons-svg/icon-bell.svg"
            alt="notifications"
            class="img"
          />
        </router-link>
      </div>
      <div class="img-box">

        <img
          @click="open"
          src="@/images/icons-svg/icon-hamburger.svg"
          alt="menu"
          class="img"
        />
      </div>
    </div> -->

    <!-- Replaced this div because it would open the old drawer behind the main drawer -->
    <!-- <div id="side-menu" :class="{ open: menu }"> -->
    <div id="side-menu">
      <div class="menu-header">
        <h3>Menu</h3>
        <div id="pencet" @click="open" :class="{ Diam: menu }">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="menu-content" id="Content">
        <div class="content-inner">
          <ul v-if="isAuthenticated" class="account" @click="toggleAccount">
            <li>
              <img :src="pfp" alt="" />
              <div class="name">
                <span class="username">{{ user.name }}</span>
                <span class="email">{{ user.email }}</span>
              </div>
            </li>
          </ul>
          <div v-else class="login">
            <ul>
              <li>
                <a href="#" @click.prevent="toLogin"> Login / Register </a>
              </li>
            </ul>

            <!-- <a href="#" @click.prevent="$emit('login')"> Register </a> -->
          </div>
          <div class="account-links" v-if="isAuthenticated">
            <div class="section">
              <ul class="items">
                <li v-for="(item, index) in items" :key="index" @click="open">
                  <router-link v-if="!item.dropdown" :to="item.link">
                    {{ item.name }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div
              v-for="group in accountLinks"
              class="section"
              :key="group.header"
            >
              <ul class="items">
                <!-- <li class="heading">{{ group.header }}</li> -->

                <li v-for="link in group.links" :key="link.link" @click="open">
                  <router-link :to="link.link">
                    {{ link.name }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <!--          <div class="theme section">-->
          <!--            <span>Theme</span>-->
          <!--            <div class="items" @click="changeTheme">-->
          <!--              <div class="theme-items">-->
          <!--                <span-->
          <!--                  class="theme-text item"-->
          <!--                  :class="{ active: theme === 'dark' }"-->
          <!--                  >Dark</span-->
          <!--                >-->
          <!--                <span class="item"> / </span>-->
          <!--                <span-->
          <!--                  class="theme-text item"-->
          <!--                  :class="{ active: theme === 'light' }"-->
          <!--                  >Light-->
          <!--                </span>-->
          <!--              </div>-->
          <!--              &lt;!&ndash; <span class="item"></span>-->
          <!--                <span class="item" v-if="theme === 'light'">Light</span>-->
          <!--                <span class="item" v-if="theme === 'dark'">Dark</span> &ndash;&gt;-->
          <!--              &lt;!&ndash; <font-awesome-icon-->
          <!--                  icon="sun"-->
          <!--                  class="icon"-->
          <!--                  v-if="theme === 'light'"-->
          <!--                />-->
          <!--                <font-awesome-icon-->
          <!--                  icon="moon"-->
          <!--                  class="icon"-->
          <!--                  v-if="theme === 'dark'"-->
          <!--                /> &ndash;&gt;-->
          <!--            </div>-->
          <!--          </div>-->
          <!-- <div class="language section">
            <span>Translate</span>

            <div class="items">
              <client-only placeholder="Loading...">
              
              </client-only>
            </div>
          </div> -->

          <div class="footer-menu">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tote from "@/components/Tote.vue";
import SiteSwitcher from "@/components/SiteSwitcher.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faAngleDown,
  faBell,
  faHome,
  faSun,
  faMoon
} from "@fortawesome/free-solid-svg-icons";
import Footer from "@/components/Footer.vue";
import CurrencyConvert from "./CurrencyConverter.vue";
import GoogleTranslate from "./GoogleTranslate.vue";
import VueCookies from "vue-cookies";
import ClientOnly from "./ClientOnly.vue";

library.add(faBars, faAngleDown, faBell, faHome, faSun, faMoon);

const UNITED_STATES = "United States";
export default {
  name: "navbar",
  components: {
    GoogleTranslate,
    CurrencyConvert,
    Tote,
    SiteSwitcher,
    FontAwesomeIcon,
    Footer
  },
  props: {
    items: Array,
    textlessLogo: Boolean,
    site: String,
    homePage: {
      type: String,
      default: "/"
    },
    accountLinks: Array
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      activeDropdown: {},
      mobile: false,
      active: false,
      menu: false,
      account: false,
      notifications: false,
      navWidth: 0,
      imageUrl: process.env.VUE_APP_IMAGE_URL,
      defaultLanguageCode: "en",
      currencies: null
    };
  },
  computed: {
    currentStoreCurrency() {
      return this.$store.state.currency;
    },
    cadOption() {
      return this.$store.state.cadCurrencyOption;
    },
    usdOption() {
      return this.$store.state.usdCurrencyOption;
    },
    theme() {
      return this.$store.state.theme;
    },
    currentSite() {
      return this.$store.state.site;
    },
    login() {
      if (this.currentSite === "planners") {
        return "/";
      } else {
        return "/login-register";
      }
    },
    buyers() {
      switch (process.env.VUE_APP_ENVIRONMENT) {
        case "development":
          return "http://localhost:3100/events";
        case "staging":
          return "https://staging.incredevent.com/events";
        default:
          return "https://incredevent.com/events";
      }
    },
    planners() {
      switch (process.env.VUE_APP_ENVIRONMENT) {
        case "development":
          return "http://localhost:3200";
        case "staging":
          return "https://planners.staging.incredevent.com";
        default:
          return "https://planners.incredevent.com";
      }
    },
    promoters() {
      switch (process.env.VUE_APP_ENVIRONMENT) {
        case "development":
          return "http://localhost:3300";
        case "staging":
          return "https://promoters.staging.incredevent.com";
        default:
          return "https://promoters.incredevent.com";
      }
    },
    isAuthenticated() {
      return this.$store.state.user.isAuthenticated;
    },
    user() {
      return this.$store.state.user;
    },
    pfp() {
      let user = this.$store.state.user;
      if (!user.profile_img) {
        return "/img/placeholders/avatar.png";
      } else {
        // process.env.VUE_APP_IMAGE_URL + response.data.profile_img;

        return process.env.VUE_APP_IMAGE_URL + user.profile_img;
      }
    },
    notificationState() {
      return this.$notifications.state;
    },
    currentsite() {
      return this.$store.state.site;
    },
    myTicketsLink() {
      switch (process.env.VUE_APP_ENVIRONMENT) {
        case "development":
          return "http://localhost:3100/my-tickets";
        case "staging":
          return "https://staging.incredevent.com/my-tickets";
        default:
          return "https://incredevent.com/my-tickets";
      }
    },
    title() {
      return this.$store.state.title;
    },
    subtitle() {
      return this.$store.state.subtitle;
    }
  },
  watch: {
    notifications(value) {
      this.$notifications.state.isViewing = value;

      if (value) {
        this.$notifications.markRead();
      }
    }
  },
  methods: {
    resetVideoSrc() {
      this.$emit("closeVideoTutorial");
      this.$store.dispatch("updateHelpVideoSrc", null);
    },
    displayOver100(number) {
      return number > 99 ? "99+" : number;
    },
    languageSelected(lang) {
      let cookie = this.getCookie("googtrans");
      if (cookie) {
        const { code, name, cname, ename } = lang;

        let currentLang = cookie.split("/")[2];
        // console.log(currentLang);
        if (code !== currentLang && code !== "en") {
          this.$bus.$emit("alert", {
            title: "Please Note",
            msg:
              "We are currently developing our multi-language capabilities. You may notice some issues with styling",
            confirm: "Okay"
          });
        }
      }
    },
    changeTheme() {
      this.$emit("changeTheme");
    },
    open() {
      // console.log("hi");

      this.menu = !this.menu;
      // let active = this.active;

      let screen = document.getElementsByTagName("BODY")[0];
      if (this.menu) {
        screen.classList.add("menu-open");
      } else {
        screen.classList.remove("menu-open");
      }
    },
    toggleActive() {
      this.active = !this.active;
    },
    toggleAccount() {
      this.account = !this.account;
      this.notifications = false;
    },
    toggleNotifications() {
      this.notifications = !this.notifications;
      this.account = false;
    },
    toLogin() {
      this.$emit("login");
      this.open();
    },
    languageSelectedHandler(info) {
      // console.log(this.defaultLanguageCode + "b");
      // this.defaultLanguageCode = info.code;
      // console.log(this.defaultLanguageCode);
    },
    getCookie(cookieName) {
      let cookie = {};
      document.cookie.split(";").forEach(function(el) {
        let [key, value] = el.split("=");
        cookie[key.trim()] = value;
      });
      return cookie[cookieName];
    }
  },
  // async mounted() {
  //   if ($cookies.isKey("currency")) {
  //     let currency = $cookies.get("currency").split("|");
  //     this.$store.dispatch("updateCurrency", {
  //       name: currency[0],
  //       value: currency[1],
  //     });
  //   } else {
  //     this.updateCurrencyByLocation();
  //   }
  //   googleTranslateDeleteCookie() {
  //     if ($cookies.isKey("googtrans")) {
  //       $cookies.remove("googtrans");
  //     }
  //   },
  // },
  mounted() {
    // this.googleTranslateDeleteCookie();

    if (document) {
      let allLinks = document
        .querySelector("div.footer")
        .getElementsByTagName("a");

      for (let i = 0; i < allLinks.length; i++) {
        const element = allLinks[i];
        element.addEventListener("click", () => {
          this.open();
        });
      }
    }

    // alert(navigator.userAgent);
    // const toMatch = [
    //   /Android/i,
    //   /webOS/i,
    //   /iPhone/i,
    //   /iPad/i,
    //   /iPod/i,
    //   /BlackBerry/i,
    //   /Windows Phone/i,
    // ];
    // let appleDevices = ["iPhone", "iPad", "iPod"];

    // appleDevices.forEach((d) => {
    //   if (navigator.userAgent.includes(d)) {
    //     console.log(navigator.userAgent);
    //     console.log(d);
    //     let navbarBottom = document.querySelector(".navbar-bottom");
    //     navbarBottom.style.paddingBottom = "25px";
    //   }
    // });

    this.onClickBody = e => {
      if (this.active && !this.$refs.inner.contains(e.target)) {
        this.active = false;
      }

      if (this.account && !this.$refs.inner.contains(e.target)) {
        this.account = false;
      }

      if (this.notifications && !this.$refs.inner.contains(e.target)) {
        this.notifications = false;
      }
    };

    document.body.addEventListener("click", this.onClickBody);

    const nav = this.$refs.nav;

    // this.navWidth = nav.clientWidth;
    // this.onResize = (e) => {
    //   if (!this.active && nav.clientWidth > this.navWidth) {
    //     this.navWidth = nav.clientWidth;
    //   }

    //   const inner = this.$refs.inner;
    //   this.mobile = this.navWidth + 275 > inner.clientWidth;

    //   // if (this.active && this.mobile) {
    //   //   this.active = false;
    //   // }
    //   // console.log(this.mobile, this.navWidth, inner.clientWidth);
    // };

    // this.onResize();

    // window.addEventListener("resize", this.onResize);
    // this.configureCurrencyBaseOnLoacation();
  },
  destroyed() {
    document.body.removeEventListener("click", this.onClickBody);
    window.removeEventListener("resize", this.onResize);
  }
};
</script>

<style lang="less">
.theme {
  // margin: 0px 15px;
}

.navbar {
  display: flex;
  width: 100%;
  position: sticky;
  top: 55px;
  height: 75px;
  background: @colors[navbar-background];
  box-shadow: 0px 3px 8px -2px rgba(0, 0, 0, 0.3);
  z-index: 14;
  border-top: 1px solid #3f444b;
  border-bottom: 1px solid #3f444b;
  &.stepper {
    top: 135px;
  }
  @media screen and (max-width: 730px) {
    &.stepper {
      top: 123px;
    }
  }
  --primary-color: @colors[primary-green];

  &.buyers {
    --primary-color: @colors[primary-blue];
  }

  &.promoters {
    --primary-color: @colors[primary-orange];
  }

  .navbar-inner {
    position: relative;
    margin: auto;
    width: 94%;
    max-width: 1100px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;

    .navbar-left {
      display: flex;
      align-items: center;
      width: 100%;

      .navbar-logo {
        display: block;
        width: 150px;
        margin-right: 20px;

        img {
          display: block;
          width: 140px;
        }
        img.mobile-logo {
          display: none;
        }
      } // end navbar-logo

      .header-text {
        color: var(--primary-golden);
        padding: 4px 20px;
        border-left: 1px solid #3f444b;
        .title {
          @media screen and (max-width: 600px) {
            font-size: 12px;
          }
        }
        .sub-title {
          font-weight: 400;
          color: grey;
          @media screen and (max-width: 760px) {
            font-size: 12px;
          }
        }
      }
    } // end navbar-left

    .navbar-right {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      column-gap: 10px;

      .links {
        display: flex;
        align-items: center;

        a {
          display: flex;
          position: relative;
          height: 75px;
          // margin-right: 20px;
          color: @colors[text];
          align-items: center;
          white-space: nowrap;
          text-align: center;
          justify-content: center;
          width: 115px;

          &.router-link-active::after,
          &.nuxt-link-active::after {
            content: " ";
            display: block;
            position: absolute;
            width: 100%;
            bottom: 0;
            border-bottom: 8px solid var(--primary-color);
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }
        }

        @media screen and (max-width: 730px) {
          display: none;
        }
      }

      .mobile-links {
        display: none;
        width: 100%;
        margin-top: 20px;
        flex-direction: column;

        a {
          display: flex;
          position: relative;
          margin-right: 20px;
          color: @colors[text];
          align-items: center;
          white-space: nowrap;
        }

        // @media screen and (max-width: 1000px) {
        //   display: flex;
        // }
      }

      .login {
        display: flex;
        align-items: center;

        a {
          display: flex;
          position: relative;
          height: 75px;
          margin-right: 20px;
          color: @colors[text];
          align-items: center;
          white-space: nowrap;
        }

        // @media screen and (max-width: 1000px) {
        //   width: 100%;
        //   flex-direction: column;
        //   align-items: flex-start;

        //   a {
        //     height: auto;
        //     margin-right: 0;
        //   }
        // }
      }
      .logout-menu {
        // padding: 0 10px 10px 10px;
        // margin: 0 5px;
        // margin-left: 15px;
        display: none;
        color: @colors[error-red];
        font-size: 14px;
      }

      @media screen and (max-width: 1000px) {
        // display: none;
        // position: absolute;
        // max-width: 300px;
        // justify-content: flex-start;
        // padding: 10px;
        // top: 63px;
        // right: -10px;
        // flex-wrap: wrap;
        // background: @colors[navbar-background];
        // border-bottom-left-radius: 5px;
        // border-bottom-right-radius: 5px;

        &.active {
          display: flex;
          z-index: 99;
        }
      }
    } // end navbar-right

    .mobile-toggle {
      display: none;
      align-items: center;
      margin-left: auto;
      font-size: 24px;
      user-select: none;

      .fa-bars {
        cursor: pointer;
      }

      @media screen and (max-width: 1000px) {
        display: flex;
      }
    }
  } // end navbar-inner

  .navbar-bottom {
    position: fixed;
    top: 100;
    bottom: 0;
    bottom: 20px;
    background: var(--dashboard-tiles);
    width: 90%;
    z-index: 555;
    color: #4b4b4c !important;
    right: 0;
    display: flex;
    left: 0;
    // justify-content: space-around;
    margin: auto;
    column-gap: 50px;
    border-radius: 10px;
    border: 1px solid #353945;
    // box-shadow: 0px 0px 10px -1px #80808061;
    align-items: center;
    // padding: 15px 25px;
    display: none;

    .img-box {
      display: flex;
      position: relative;
      margin: 5px 0;

      /* margin: 13px 0; */
      // width: 60px;
      // height: 60px;
      // border-radius: 100%;
      // align-items: center;
      // justify-content: center;
      span.number {
        color: white;
        display: flex;
        position: absolute;
        width: 17px;
        height: 17px;
        top: 2px;
        right: 20px;
        /* padding: 1px; */
        justify-content: center;
        flex-direction: row;
        align-items: center;
        background: var(--error-red);
        border-radius: 100%;
        font-size: 10px;
        font-weight: bold;
        color: white;
        border: 2px solid var(--navbar-background);
        z-index: 2;
      }
      a {
        display: flex;
        border-radius: 100%;
        height: 45px;
        width: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s background;

        &:active,
        &:hover {
          background: var(--mobile-hover);
        }
      }
      a.nuxt-link-exact-active.nuxt-link-active,
      a.router-link-exact-active.router-link-active {
        .img {
          filter: none;
        }
        // content: " ";
        // display: block;
        // position: absolute;
        // width: 100%;
        // bottom: 0;
        // border-bottom: 5px solid var(--primary-color);
        // border-top-left-radius: 4px;
        // border-top-right-radius: 4px;
      }
    }
    .tote-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      span.tote-number {
        position: absolute;
        z-index: 10;
        color: white;
      }
    }

    .img {
      cursor: pointer;
      width: 27px;
      // width: 40px;
      filter: contrast(0.2) sepia(100%) hue-rotate(116deg) brightness(1.1)
        saturate(0);

      // &:hover {
      //   filter: none;
      // }
    }
    .img.tote {
      width: 36px;
    }
  }
  #side-menu {
    overflow: auto;
    position: fixed;
    visibility: hidden;
    transition: visibility 0.4s linear, right 0.4s ease-in-out;
    width: 100%;
    height: 100%;
    top: 99px;
    background-image: var(--dashboard-tiles);
    right: -1000px;
    z-index: 2222;

    &.open {
      right: 0px;
      visibility: visible;
    }

    .menu-header {
      border-bottom: 1px solid #343940;
      position: fixed;
      width: 100%;
      z-index: 11;
      background: inherit;
      h3 {
        padding: 12px 14px;
        margin: 0;
        color: var(--text);
        font-weight: bold;
      }
      #pencet {
        display: flex;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 11px;
      }

      #pencet span {
        background-color: #b8b8b8;
        width: 2em;
        height: 3px;
        border-radius: 5px;
        margin: 0.26em 0;
        display: block;
        transition: all 0.4s ease;
        transform-origin: 0 0;
      }

      .Diam span:nth-child(1) {
        transform: rotate(40deg) translate(2px, -1px);
      }

      .Diam span:nth-child(2) {
        transform: scaleX(0);
      }

      .Diam span:nth-child(3) {
        transform: rotate(-44deg) translate(1px, 0);
      }
    }
    .menu-content {
      // height: 100%;
      min-height: initial;
      margin-top: 60px;
      .content-inner {
        padding: 0;
        width: 100%;

        ul {
          padding: 0;
          list-style: none;
          width: 100%;
          margin: 0;
          padding: 0;
          // box-shadow: 0 6px 10px 0 rgb(0 0 0%);
          color: var(--mobile-menu-text);
          border-bottom: 1px solid #454545;

          li {
            flex-wrap: wrap;
            padding: 15px 30px;
            // width: 100%;
            display: flex;
            align-items: center;
            padding: 12px 14px;
            position: relative;
            transition: 0.4s;
            cursor: pointer;
          }
        }

        .footer {
          display: block;
          background: none;

          .copy {
            box-shadow: initial;
            background: none;
          }
        }
      }
      .account {
        color: var(--mobile-menu-text);
        display: flex;
        padding: 12px 14px;

        position: relative;
        align-items: center;
        flex-wrap: wrap;
        cursor: pointer;
        width: fit-content;
        // margin: auto;
        img {
          width: 50px;
          height: 50px;
          margin-right: 10px;
          // border: 1px solid @colors[navbar-pfp-border];
          border-radius: 100%;
          object-fit: cover;
        }

        .name {
          display: flex;
          flex-direction: column;
          height: 100%;
          .username {
            font-weight: bold;
            color: var(--text);
          }
          .my-account {
            color: @colors[text-secondary];
            font-size: 12px;
          }
        }
        .logout {
          padding: 0 10px 10px 10px;
          color: @colors[error-red];
          font-size: 14px;
        }

        .account-dropdown {
          display: flex;
          position: absolute;
          top: 58px;
          left: -20px;
          width: 200px;
          flex-direction: column;
          background: @colors[navbar-background];
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          cursor: default;
          // z-index: 99;

          .section {
            margin-bottom: 5px;

            span {
              padding: 5px 10px;
              color: @colors[text-secondary];
              font-size: 12px;
              text-transform: uppercase;
            }

            .items {
              display: flex;
              flex-direction: column;

              a {
                color: @colors[text];
                padding: 0 10px;
                font-size: 14px;
                cursor: pointer;
              }
            }
          }

          .logout {
            padding: 0 10px 10px 10px;
            color: @colors[error-red];
            font-size: 14px;
          }
        }

        // @media screen and (max-width: 1000px) {
        //   margin-right: 15px;
        //   order: -1;
        // }
      }
      .login {
        display: flex;
        align-items: center;
        // width: ;
        margin: auto;
        a {
          display: flex;
          position: relative;
          // height: 75px;
          // margin-right: 20px;
          color: @colors[text];
          align-items: center;
          // white-space: nowrap;
        }

        // @media screen and (max-width: 1000px) {
        //   width: 100%;
        //   flex-direction: column;
        //   align-items: flex-start;

        //   a {
        //     height: auto;
        //     margin-right: 0;
        //   }
        // }
      }
      .account-links {
        .section {
          ul.items {
            padding: 0;
            list-style: none;

            li {
              padding: 0;
              a {
                padding: 12px 14px;
                color: var(--mobile-menu-text);
                width: 100%;
                transition: 0.5s;
                transition-property: background;
                &:active,
                &:hover {
                  background: #2b2e34;
                }
              }
            }

            // margin: 20px;
          }
        }
      }

      .language.section,
      .theme.section {
        padding: 12px 14px;
        border-bottom: 1px solid #454545;

        li {
          padding: 0 12px;
        }
        .eo__languages.notranslate .eo__dropdown .eo__dropdown__menu {
          width: 100%;
        }

        .items {
          display: flex;
          flex-direction: column;
          padding: 12px 0;
          cursor: pointer;
          a {
            // color: @colors[text];
            font-size: 14px;
            cursor: pointer;
          }

          .theme-items {
            // padding: 0 10px;
            span {
              padding: 0;

              &.active {
                color: var(--primary-green);
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    // top: 25px;
    height: 50px;

    .navbar-inner {
      width: 94%;

      .navbar-left {
        .navbar-logo {
          width: initial;
          margin-right: 10px;

          img.desktop-logo {
            display: none;
          }
          img.mobile-logo {
            display: block;
            width: 40px;
            max-width: 40px;
          }
        }
      }
    }
    // .navbar-bottom {
    //   display: flex;
    // }
  }

  @media screen and (max-width: 730px) {
    .navbar-inner {
      .navbar-right {
        .switcher {
          margin-right: 0;
        }
        .tote,
        .account,
        .mobile-links,
        .notifications,
        .login {
          display: none;
        }

        .logout-menu {
          display: block;
        }
      }
    }
    .navbar-bottom {
      display: flex;
    }
  }
} // end navbar

@media screen and (max-width: 890px) {
  .navbar.planners {
    .navbar-inner {
      .navbar-right {
        .switcher {
          margin-right: 0;
        }
        .tote,
        .account,
        .mobile-links,
        .notifications,
        .login {
          display: none;
        }

        .logout-menu {
          display: block;
        }
      }
    }
    .navbar-bottom {
      display: flex;
    }
  }
}
</style>
