<template>
  <div class="banner">
    <router-link to="/covid-relief"
      >Change your bottom line. Click for Covid relief fees.</router-link
    >
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.banner {
  position: sticky;
  top: 0;
  justify-content: center;
  // width: 100%;
  height: 35px;
  display: flex;
  font-weight: 600;
  align-items: center;
  z-index: 50;
  background: var(--primary-green);

  &.stepper {
    top: 80px;
  }
  a {
    color: white;
  }
}
@media screen and (max-width: 730px) {
  .banner {
    &.stepper {
      top: 72px;
    }
  }
}
@media screen and (max-width: 600px) {
  .banner {
    height: 25px;
    font-size: 14px;
    padding: 0 8px;
  }
  // a {
  // }
}
@media screen and (max-width: 400px) {
  .banner {
    font-size: 3.5vw;
  }
  // a {
  // }
}
</style>