<template>
  <modal title="Redirecting..." :active="active" v-on:close="close">
    <div class="alert-message">
      <h4 class="b1" :msg="msg">{{ msg }}</h4>
      <h4 id="loader-text" :timer="timer">{{ timer }}</h4>
      <div class="loader" :timer="timer">
        <!--<p id="loader-text">{{timer}}</p>-->
      </div>
    </div>
    <template v-slot:footer>
      <diyobo-button type="secondary" txt="Ok" v-on:click="close" />
    </template>
  </modal>
</template>

<style lang="less" scoped>
.modal {
  .modal-inner {
    .modal-dialog {
      .modal-body {
        .info-text {
          padding-bottom: 0.5em;
          text-align: center;
          font-weight: 600;

          a {
            color: #4a639e;
          }
        }

        h2 {
          margin-bottom: 0;
        }
        .dropdown-wrapper1 {
          margin-bottom: -23px;
        }

        .loader {
          border: 8px solid #f3f3f3;
          border-top: 8px solid #4eb95e;
          border-radius: 50%;
          width: 60px;
          height: 60px;
          animation: spin 1s ease-in-out infinite;
          margin-bottom: 20px;
          position: relative;
        }

        .alert-message {
          display: flex;
          justify-content: center;
          flex-flow: column;
          align-items: center;

          .b1 {
            justify-self: flex-start;
            margin-bottom: 20px !important;
          }

          #loader-text {
            position: absolute;
            padding-top: 25px;
          }
        }
        //end alert-message

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}
</style>

<script>
import Modal from "@/components/Modal.vue";
import DiyoboButton from "@/components/DiyoboButton";
import { createSession } from "../../helpers/auth.js";

export default {
  components: {
    Modal,
    DiyoboButton
  },
  props: {
    active: Boolean,
    Msg: String,
    redirectLink: String,
    bus: Object
  },
  watch: {
    active(val) {
      this.modalActive = val;
      if (val) {
        this.timer = 5;
        this.beginInterval();
      }
    },
    Msg(val) {
      this.msg = val;
    },
    redirectLink(val) {
      this.link = val;
    }
  },
  methods: {
    beforeEnter(el) {
      this.$refs.dialog.style.top = "-100%";
      el.style.opacity = 0;
      document.body.style.overflow = "hidden";
    },
    enter(el) {
      el.style.opacity = 1;
    },
    afterEnter() {
      this.$refs.dialog.style.top = "0%";
    },
    beforeLeave() {
      this.$refs.dialog.style.top = "-100%";
    },
    leave(el) {
      el.style.opacity = 0;
      document.body.style = "";
    },
    close(timerFunc) {
      if (timerFunc) {
        clearInterval(timerFunc);
      }
      this.modalActive = false;
      this.$emit("dismiss");
      this.$router.push(this.link);
    },
    beginInterval(val) {
      let self = this;
      let timerFunc = setInterval(function() {
        if (self.timer == 0) {
          self.close(timerFunc);
        } else {
          self.timer--;
        }
      }, 1000);
    }
  },
  data() {
    return {
      title: "",
      msg: this.Msg ? this.Msg : "",
      confirm: "Okay",
      modalActive: this.active,
      onConfirm: null,
      timer: 0,
      link: this.redirectLink ? this.redirectLink : "",
      timerFunc: ""
    };
  },
  mounted() {
    // let self = this
    // if(this.modalActive) {
    //     console.log('im in that if')
    //     let timerFunc = setInterval(() => {
    //         console.log('hahahahah')
    //         if(self.timer == 0) {
    //             self.close(timerFunc)
    //         }
    //         else
    //         self.timer--
    //     }, 1000)()
    // } else {
    //     console.log('idk')
    // }
  }
};
</script>
