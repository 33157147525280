<template>
  <div id="app" :class="theme" data-app>
    <alert-modal ref="alert" />
    <redirect-modal
      :active="redirectModal"
      :Msg="redirectModalMsg"
      :redirectLink="redirectModalLink"
      v-on:dismiss="dismiss('redirectModal')"
    />
    <loading-modal />
    <creating-modal />
    <diyobo-name-update-modal ref="diyoboNameUpdateModal" />
    <steppers
      :steps="stepsCom"
      class="planner"
      v-if="!isStepper"
      @stepperComplete="stepperComplete"
    />
    <!-- <site-wide-banner
      :class="{ stepper: !isStepper }"
      v-if="isWeddingSocial !== 'wedding-socials'"
    /> -->
    <!-- COMMENT -->
    <upper-navbar
      :class="{ stepper: !isStepper }"
      :account-links="accountItems"
      @changeTheme="changeTheme"
      v-on:login="$router.push('/')"
      v-on:register="$router.push('/')"
      v-on:logout="login"
      ref="upperNavbar"
    />
    <navbar
      v-if="isWeddingSocial !== 'wedding-socials'"
      site="planners"
      :items="navItems"
      :class="{ stepper: !isStepper }"
      :accountLinks="accountItems"
      @changeTheme="changeTheme"
      v-on:login="$router.push('/')"
      v-on:register="$router.push('/')"
      v-on:logout="login"
      @closeVideoTutorial="closeVideoTutorial"
    />
    <transition name="fade" mode="out-in">
      <router-view
        v-if="authenticated"
        class="main-cont"
        :class="{ stepper: !isStepper }"
      />
    </transition>
    <div v-if="!authenticated" id="Loading">
      <div id="Header">
        <div class="header-inner"></div>
      </div>
      <div id="Content">
        <div class="content-inner"></div>
      </div>
    </div>
    <Footer v-on:logout="login" />
    <!-- end footer -->
  </div>
</template>

<style lang="less">
@import "https://use.typekit.net/mqb5euw.css";
@import (css)
  url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
// init css variables for colors
.init-color-vars();
* {
  font-family: "Open Sans", sans-serif;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --primary: @colors[primary-green];
  --secondary: @colors[secondary-green];
}

a {
  color: var(--primary-green);
}

.main-cont.stepper {
  margin-top: 100px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

// end Animations
.card {
  border-radius: 10px;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body.menu-open {
  overflow: hidden;
}

body.loading {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
  color: @colors[headers];
  margin: 20px 0;
}

h1 {
  font-size: 48px;
  font-weight: 700;
}

button.profile {
  background: var(--secondary-green) !important;
}

@media only screen and (max-width: 500px) {
  h1 {
    font-size: 9vw;
  }
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

.b1,
.b2,
.b3 {
  color: @colors[text];
}

.b1 {
  font-size: 16px;
}

@media only screen and (max-width: 500px) {
  .b1 {
    font-size: 13px;
  }
}

.b2 {
  font-size: 14px;
}

.b3 {
  font-size: 12px;
}

p {
  font-size: 14px;
  line-height: 160%;
  margin: 10px 0;
}

a {
  text-decoration: none;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text);
  background-color: var(--content-background);
  // overflow-x: hidden;
}

div.Vue-Toastification__toast--success {
  background-color: #43b777;
}

#Header {
  // height: 165px;
  // background-image: url("./assets/TT_SubHeader_BG.jpg");
  // background-position: center;
  background: rgb(20, 21, 25);
  background: radial-gradient(
    circle,
    rgba(20, 21, 25, 1) 50%,
    rgba(33, 35, 40, 1) 75%
  );
  z-index: 5;
  position: relative;

  // background-size: contain;
  .header-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin: auto;
    width: 94%;
    max-width: 1100px;
    height: 100%;

    .page-title {
      display: flex;
      margin: 10px auto;

      h1 {
        font-size: 2.25em;
      }

      h2 {
        // margin-bottom: 1.25em;
        margin-left: 0.4em;
        font-size: 1.25em;
        font-weight: 400;
        color: var(--subtitle);
      }
    }

    // end page-title
  }

  // end header-inner
}

// end Header
#app.light #Header {
  background-image: none;
  background: var(--primary-green);

  .header-inner .page-title h1 {
    color: white;
  }
}

#Content {
  // position: relative;
  z-index: 10;
  min-height: calc(100vh - 208px);

  //background-color: var(--content-base);

  // .shadow {
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   // left: -25%;
  //   // z-index: 1;
  //   width: 100%;
  //   height: 100%;
  //   box-shadow: inset 0 0 20px 0px @colors[content-shadow];
  // }

  .content-inner {
    margin: auto;
    padding-top: 15px;
    padding-bottom: 100px;
    position: relative;
    // z-index: 2;
    width: 94%;
    max-width: 1100px;
    overflow: visible;

    section {
      margin-bottom: 2em;
    }

    // end section

    .content-path {
      color: #999999;
      padding-bottom: 15px;

      a {
        color: #999999;
      }
    }

    .divider {
      margin: 1em auto;
      width: 100%;
      border-bottom: 1px solid #3c4048;
    }

    .events {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: flex-start;
      margin-left: -0.7533%;
      padding: 2em 0;
      width: 101.5066%;
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      margin: initial;
      // margin-left: -0.5em;
      // width: calc(100% + 1em);
      width: 100%;
      justify-content: space-between;

      .col {
        flex-basis: initial;
        flex-grow: initial;
        padding: 0;

        &.flex {
          display: flex;
          margin: auto;
        }

        &.center {
          justify-content: center;
        }

        &.full {
          width: 100%;
        }

        // end full col

        &.half {
          width: calc(50% - 10px);
        }

        // end half col

        &.quarter {
          width: calc(25% - 10px);
        }

        // end 1/4 col

        &.third {
          width: calc(33.33% - 10px);
        }

        &.quarterx3 {
          width: calc(75% - 10px);
        }

        // end 3/4 col

        &.eighth {
          width: calc(12.5% - 10px);
        }

        &.tenth {
          width: calc(10%);
        }
      }

      // end col
    }

    // end row
  }

  // end content-inner

  @media screen and (max-width: 600px) {
    .content-inner {
      width: 90%;
    }
  }
  @media screen and (max-width: 730px) {
    .content-inner {
      padding-bottom: 220px;
    }
  }
}

@media screen and (max-width: 620px) {
  #Content {
    min-height: calc(100vh - 158px);
  }
}

// media queries
// min 790px
@media screen and (min-width: 790px) {
  .page-title {
    display: flex;
    align-items: baseline;

    h2 {
      margin-left: 0.5em;

      &::before {
        margin-right: 0.25em;
        content: "-";
      }
    }
  }
}

// max 789px
@media screen and (max-width: 789px) {
  .page-title {
    h1 {
      margin: 0;
    }

    h2 {
      margin: 0;
      margin-bottom: 0.5em;
    }
  }
}

// max 749px
@media screen and (max-width: 749px) {
  #Content {
    .content-inner {
      .row {
        .col {
          &.half,
          &.third {
            width: 100%;
          }

          // end col segments
        }

        // end col
      }

      // end row
    }

    // end content-inner
  }

  // end content
}

// end 749px breakpoint

@media screen and (max-width: 600px) {
  #Header {
    .header-inner {
      .page-title {
        h1 {
          font-size: 1.75em;
        }

        h2 {
          // margin-bottom: 0.75em;
          font-size: 1.5em;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  #Header {
    .header-inner {
      .page-title {
        h1 {
          font-size: 1.25em;
        }
      }
    }
  }
}
</style>

<script>
import decode from "jwt-decode";
import AlertModal from "@/components/modals/AlertModal.vue";
import LoadingModal from "@/components/modals/LoadingModal.vue";
import SiteSwitcher from "@/components/SiteSwitcher";
import CreatingModal from "./components/modals/CreateEventModal.vue";
import RedirectModal from "./components/modals/redirectModal.vue";
import Tote from "@/components/Tote.vue";
import ModalContent from "@/helpers/modals";
import {
  destroySession,
  getSessionCookie,
  SESSION_COOKIE
} from "./helpers/auth.js";
import { getLocationCookie, setLocationCookie } from "@/helpers/location";
import Navbar from "@/components/Navbar.vue";
import SiteWideBanner from "./components/SiteWideBanner.vue";
import Footer from "@/components/Footer.vue";
import DiyoboNameUpdateModal from "@/components/modals/DiyoboNameUpdateModal.vue";
import { getThemeCookie } from "../../shared/helpers/theme";
import Steppers from "@/components/Steppers.vue";
import UpperNavbar from "@/components/UpperNavbar.vue";

export default {
  name: "app",
  components: {
    AlertModal,
    LoadingModal,
    SiteSwitcher,
    RedirectModal,
    CreatingModal,
    Tote,
    Navbar,
    SiteWideBanner,
    Footer,
    DiyoboNameUpdateModal,
    Steppers,
    UpperNavbar
  },
  head() {
    return {
      title: "",
      titleTemplate: title =>
        title ? `${title} • INCREDEVENT Planners` : "INCREDEVENT Planners"
    };
  },
  data() {
    return {
      navItems: [
        { name: "My Brands", link: "/brands" },
        { name: "My Events", link: "/dashboard" },
        { name: "Resources", link: "/resources" }
      ],
      accountItems: [
        {
          header: "Account",
          links: [{ name: "Profile", link: "/profile" }]
        },
        {
          header: "Brands",
          links: [{ name: "My Brands", link: "/brands" }]
        },
        {
          header: "Events",
          links: [{ name: "My Events", link: "/my-events" }]
        }
      ],
      // steps: [
      //   {
      //     number: 1,
      //     name: "Complete Profile",
      //     complete: false,
      //     active: false,
      //     link: "/profile"
      //   },
      //   {
      //     number: 2,
      //     name: "Fill out Brand Info",
      //     complete: false,
      //     active: false,
      //     link: "/brand-settings"
      //   },

      //   {
      //     number: 3,
      //     name: "Launch 1st Event",
      //     complete: false,
      //     active: false,
      //     link: "/create-event"
      //   },
      //   {
      //     number: 4,
      //     name: "Complete",
      //     complete: false,
      //     active: false
      //   }
      // ],
      steps: [
        {
          number: 1,
          name: "Identity Protection",
          complete: false,
          active: false,
          link: "/id-protection"
        },
        {
          number: 2,
          name: "Add Brand",
          complete: false,
          active: false,
          link: "/brands/add-brand"
        },
        {
          number: 3,
          name: "Brand Page",
          complete: false,
          active: false,
          link: ""
        },
        {
          number: 4,
          name: "Payouts",
          complete: false,
          active: false,
          link: ""
        },

        {
          number: 5,
          name: "Create Event",
          complete: false,
          active: false,
          link: "/create-event"
        }
      ],
      auth: false,
      hideStepper: true,
      redirectModal: false,
      redirectModalMsg: "",
      redirectModalLink: "",
      authenticated: false
    };
  },
  computed: {
    theme() {
      return this.$store.state.theme;
    },
    isWeddingSocial() {
      // if (this.$route.path == "wedding-socials") {
      //   return true;
      // }
      return this.$route.path;
      // if (this.$route.path === "wedding-socials") {
      //   return true;
      // }
    },
    session_id: {
      get() {
        return this.$store.state.session_id;
      },
      set(id) {
        this.$store.state.session_id = id;
        return id;
      }
    },
    stepsCom() {
      let user = this.$store.state.user;

      let activeBrand = this.$store.state.activeBrand || null;

      let subdomain = activeBrand ? activeBrand.subdomain : "";

      if (user.coordVerified) {
        this.steps[0].complete = true;
      } else {
        this.steps[0].complete = false;
        // this.$router.push(this.steps[0].link);
      }

      if (user.plannerBrands > 0) {
        this.steps[1].complete = true;
        this.steps[1].active = false;
      } else {
        this.steps[1].complete = false;
        // this.$router.push(this.steps[1].link);
      }

      // temp solution for storing brand completion
      let plannerPage = sessionStorage.getItem("brandCompleted");
      if (user.plannerPage > 0 || plannerPage) {
        this.steps[2].complete = true;
      } else if (this.steps[1].complete && activeBrand) {
        // this.steps[2].link = `${this.steps[2].link}/${subdomain}`;
        // this.$router.push(`${this.steps[2].link}/${subdomain}`);
      } else {
        this.steps[2].complete = false;
      }

      if (user.plannerPayouts > 0) {
        this.steps[3].complete = true;
      } else if (this.steps[2].complete && activeBrand) {
        // this.steps[3].link = `${this.steps[3].link}/${subdomain}`;
        // this.$router.push(`${this.steps[3].link}/${subdomain}`);
      } else {
        this.steps[3].complete = false;
      }

      if (
        user.plannerEvents > 0 &&
        this.steps[0].complete &&
        this.steps[1].complete &&
        this.steps[2].complete &&
        this.steps[3].complete
      ) {
        setTimeout(() => {
          // this.steps[3].complete = true;
          this.steps[4].complete = true;
        }, 2000);
      } else {
        this.steps[4].complete = false;
      }

      // if (this.steps[1].complete && this.steps[2].complete) {
      //   setTimeout(() => {
      //     // this.steps[3].complete = true;
      //     this.steps[3].complete = true;
      //   }, 2000);

      //   // setTimeout(() => (steps[3].complete = true), 2000);
      // } else {
      //   this.steps[3].complete = false;
      // }

      return this.steps;
    },
    user() {
      return this.$store.state.user;
    },
    isStepper() {
      let user = this.$store.state.user;
      user.hidePlannerStepper = user.hidePlannerStepper || false;
      if (user.isAuthenticated && !user.hidePlannerStepper) {
        this.hideStepper = false;
      } else {
        this.hideStepper = true;
      }
      return this.hideStepper;
    }
  },
  watch: {
    // steps: {
    //   handler(refss) {
    //     console.log(refss);
    //     if (refss[2].complete === true) {
    //       // setTimeout(() => {
    //       this.$set(this.steps[3], "complete", true);
    //       // this.steps[3].complete = true;
    //       // }, 2000);
    //     }
    //   },
    //   deep: true
    // }
  },
  methods: {
    closeVideoTutorial() {
      this.$refs.upperNavbar.closeVideo();
    },
    stepperComplete() {
      this.$axios
        .post("/account/update-stepper", { site: "planner" })
        .then(({ data }) => {
          if (data.success) {
            this.hideStepper = true;
            this.$store.commit("updateUserProperty", {
              property: "hidePlannerStepper",
              value: true
            });
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    changeTheme() {
      this.theme = dark;
      this.$store.dispatch("toggleTheme");

      if (this.user.isAuthenticated) {
        this.$axios.post("/account/update-theme", {
          theme: this.theme
        });
      }
    },
    login: function() {
      this.$store.dispatch("updateHelpVideoSrc", null);
      let self = this;
      if (this.user.isAuthenticated) {
        sessionStorage.clear();
        destroySession(self);

        self.$refs.alert.open(ModalContent.logout);

        self.$notifications.disconnect();
        // self.$axios.post("/auth/deauthenticate").then(function (response) {
        //   document.cookie = `session=null; expires=Thu, 18 Dec 2013 00:00:00 UTC`;
        //   // self.user.name = null;
        //   // self.user.email = null;
        //   // self.user.isAuthenticated = false;

        // });
      } else {
        self.$axios.post("/auth/authenticate").then(function(response) {
          document.cookie = `session=${response.data.session_id}`;
          self.user.session_id = response.data.session_id;
          self.user.name = response.data.name;
          self.user.email = response.data.email;
          self.user.isAuthenticated = true;
        });
      }
      this.$router.push("/");
    },
    dismiss(val) {
      this[val] = false;
      document.getElementsByTagName("body")[0].style.overflow = "visible";
    },
    checkCookieExpiration() {
      setInterval(() => {
        const myCookie = getSessionCookie();
        const expirationDate = new Date(myCookie);
        const currentDate = new Date();
        if (
          this.$route.path != "/" &&
          (!myCookie || expirationDate < currentDate)
        ) {
          sessionStorage.clear();
          destroySession(this);
          this.$notifications.disconnect();
          this.$store.dispatch("logout");
          this.$router.push("/");
        }
      }, 600000);
    }
  },
  created() {
    this.$store.commit("setTheme", getThemeCookie());
    // this.$store.dispatch("loadUser");
    // get session storage for brand

    // this.$store.commit("setBrand")
  },
  //TESTM
  async mounted() {
    this.checkCookieExpiration();
    this.$nextTick(async () => {
      (function(d) {
        var s = d.createElement("script");
        s.setAttribute("data-account", "ADbBFJvTvP");
        s.setAttribute("src", "https://cdn.userway.org/widget.js");
        (d.body || d.head).appendChild(s);
      })(document);

      var cookie = document.cookie.split(";");
      var cookies = {};
      cookie.forEach(el => {
        let keyvar = el.split("=");
        cookies[keyvar[0].trim()] = keyvar[1];
      });

      this.$axios.interceptors.request.use(
        config => {
          config.headers["Authorization"] = `Bearer ${getSessionCookie()}`;
          return config;
        },
        error => Promise.reject(error)
      );

      this.$axios.interceptors.response.use(
        response => {
          return response;
        },
        error => {
          if (error.response.status === 401) {
            const cookie = getSessionCookie();
            if (cookie) {
              document.cookie = `${SESSION_COOKIE}=${cookie}; expires=Thu, 18 Dec 2013 12:00:00 UTC`;
            }
            sessionStorage.clear();
            destroySession(self);
            self.$notifications.disconnect();
            this.$store.dispatch("logout");

            if (!this.$route.meta.allowAnonymous) {
              this.$router.push("/");
            }
          } else if (error.response.status === 403) {
            this.$router.push("/brands");
          } else if (error.response.status === 400) {
            // TODO: implement better
            this.$refs.alert.open({
              title: "An Error Has Occured.",
              msg:
                "We're sorry to say but you have hit an error that has not been reported yet. We can help you out quickly though. Just message into our chat and we'll get your going."
            });
          }

          // TODO: Once all the loader stuff is done we need to handle this a bit better
          this.$loader.stop();

          return Promise.reject(error);
        }
      );

      let self = this;

      this.$bus.$on("alert", alert => {
        this.$refs.alert.open(alert);
      });

      this.$bus.$on("redirectVerification", id => {
        this.$router.push(`/verify?id=${id}`);
        this.$toast("Please verify your email.", { type: "success" });
        return;
      });

      // this.$root.$on("alert", alert => {
      //   this.$refs.alert.open(alert);
      // });

      this.$store.state.bus.$on("alert", alert => {
        this.$refs.alert.open(alert);
      });

      this.$root.$on("show-diyobo-name-update-modal", () => {
        this.$refs.diyoboNameUpdateModal.open();
      });

      // if (document.referrer === "https://admin.tickettote.com/") {
      //   await createSession(this.$route.query.login, null, self, "admin");
      // }

      if (cookies[SESSION_COOKIE]) {
        this.$loader.start();
        try {
          const decoded = decode(cookies[SESSION_COOKIE]);
          this.$axios.post("/auth/verify").then(response => {
            const data = response.data;
            if (data.authorized) {
              this.$store.commit("setUser", data.user);
              this.$store.commit("setData", decoded);

              let location = data.user.location;
              if (location && !getLocationCookie()) {
                if (location.split("-").length === 3) {
                  location = location.replace(/\-/g, "|");
                }

                const parts = location.split("|");

                if (parts.length === 3) {
                  setLocationCookie(parts[0], parts[1], parts[2]);
                }
              }

              const theme = getThemeCookie();
              if (theme === "system" && data.user.theme) {
                this.$store.commit("setTheme", data.user.theme);
              }

              let brandExist =
                sessionStorage.activeBrandName &&
                sessionStorage.activeBrandSubdomain;
              let newActiveBrand = {
                name: sessionStorage.activeBrandName,
                subdomain: sessionStorage.activeBrandSubdomain
              };
              let activeBrand = brandExist
                ? newActiveBrand
                : this.$store.state.user.activeBrand;

              if (activeBrand) {
                this.$store.commit("setBrand", activeBrand);
              }

              const notificationsUrl =
                process.env.VUE_APP_NOTIFICATIONS_SOCKET +
                "?session_id=" +
                decoded.session_id;

              this.$notifications.connect(notificationsUrl);
              this.$notifications.init("planners");

              if (
                this.user.diyobo &&
                this.user.first_name === "Your" &&
                this.user.last_name === "Name"
              ) {
                this.$root.$emit("show-diyobo-name-update-modal");
              }

              let q = this.$route.query;
              if (q.redirect) {
                this.$router.replace(q.redirect);
              } else if (this.$route.name === "home") {
                this.$router.replace("/brands");
              }
            } else {
              const route = this.$route;
              // if (!route.meta.allowAnonymous && !isAuthenticated()) {
              //   this.$router.push({
              //     path: "/",
              //     query: { redirect: route.fullPath },
              //   });
              // }
            }

            this.authenticated = true;
            this.$store.state.authenticated = true;
            this.$loader.stop();
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        this.authenticated = true;
        this.$store.state.authenticated = true;
        // const route = this.$route;
        // // console.log(this.$route, isAuthenticated);
        // if (!route.meta.allowAnonymous && !isAuthenticated()) {
        //   this.$router.push({
        //     path: "/",
        //     query: { redirect: route.fullPath },
        //   });
        // }
      }
    });
  }
};
</script>
