<template>
  <modal :active="active" title="Update Account Name" :closable="false">
    <div class="msg b1">
      To finish migrating your account, we just need you confirm your first and
      last name.
    </div>
    <diyobo-input
      type="text"
      label="First Name"
      placeholder="John"
      v-model="firstName"
      :error="errors[0].error"
      :required="true"
    />
    <diyobo-input
      type="text"
      label="Last Name"
      placeholder="Doe"
      v-model="lastName"
      :error="errors[1].error"
      :required="true"
    />
    <template v-slot:footer>
      <diyobo-button type="secondary" txt="O-to-the-K" v-on:click="submit" />
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";

export default {
  name: "diyobo-name-update-modal",
  components: {
    Modal,
    DiyoboInput,
    DiyoboButton
  },
  data() {
    return {
      active: false,
      firstName: "",
      lastName: "",
      errors: [
        { name: "firstName", error: false },
        { name: "lastName", error: false }
      ]
    };
  },
  methods: {
    open() {
      this.active = true;
    },
    async submit() {
      for (const error of this.errors) {
        error.error = !this[error.name];
      }

      if (!this.errors.some(e => e.error)) {
        await this.$axios.post("/account/update-name", {
          firstName: this.firstName,
          lastName: this.lastName
        });

        this.$store.commit("setUserName", {
          firstName: this.firstName,
          lastName: this.lastName
        });

        this.close();
      }
    },
    close() {
      this.firstName = "";
      this.lastName = "";
      this.active = false;
    }
  }
};
</script>

<style lang="less" scoped>
.modal {
  .modal-inner {
    .modal-dialog {
      .modal-body {
        .msg {
          padding-bottom: 20px;
        }
      }
    }
  }
}
</style>
