<template>
  <div>
    <multiselect
      ref="multiselect"
      v-model="value"
      :options="options"
      :placeholder="context.placeholder"
      label="label"
      :multiple="true"
      selectLabel=""
      selectedLabel=""
      deselectLabel="✖"
      :showLabels="true"
      track-by="value"
      :close-on-select="false"
      :preserve-search="true"
      @select="onSelect"
      @remove="onSelect"
      :disabled="context.attributes.disabled"
      @search-change="emitInput"
    >
      <template
        #selection="{ values,  isOpen }"
        v-if="context.attributes.multiple"
      >
        <span class="multiselect__single" v-if="values.length" v-show="!isOpen"
          >{{ values.length }} options selected</span
        >
      </template>

      <span slot="noResult"
        >Oops! Nothing found. Try changing the search query.</span
      >
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "TTSearchMultiSelect",
  components: {
    Multiselect,
  },
  props: {
    context: Object,
  },
  data() {
    return {
      value: [],
    };
  },
  computed: {
    options() {
      return this.context.options || [];
    },
    placeholder() {
      return this.context.attributes.placeholder;
    },
  },
  methods: {
    onSelect(selectedOption) {
      this.context.model = this.value.map((option) => option.value);
    },
    emitInput(input) {
      this.$emit("searchChange", input);
    },
  },
  mounted() {
    if (this.context.model) {
      this.value = this.context.model.map((option) => {
        return this.options.find((o) => o.value === option);
      });
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="less" scoped>
::v-deep {
  .multiselect--active {
    z-index: 5;
  }
  .multiselect__input,
  .multiselect__single {
    background: var(--input-background);
    color: #fff;
    margin-bottom: 0;
    padding: 0;
    line-height: initial;
  }

  .multiselect__single {
    background: var(--input-background);
    color: #fff;
    margin-bottom: 0;
    padding: 0;
  }

  .multiselect__content {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 0;
    min-width: 100%;
    vertical-align: top;
    background-image: var(--options-background);
    color: #fff;
    z-index: 15;
  }

  .multiselect__select {
    min-height: 40px;
    padding-top: 12px;
  }
  .multiselect--disabled,
  .multiselect--disabled .multiselect__select {
    user-select: none;
    cursor: not-allowed;
    background: none;
  }

  .multiselect__placeholder {
    font-size: 16px;
    margin-bottom: 0;
    color: var(--text);
  }
  .multiselect__tags {
    min-height: 40px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    padding: 12px 40px 12px 12px;
    border-radius: 8px !important;
    border: 1px solid var(--input-border);
    background: var(--input-background);
    font-size: 14px;
  }

  .multiselect__tag {
    margin-bottom: initial;
  }

  .multiselect__option {
    background: none !important;
    border-bottom: 1px solid var(--input-border);
    line-height: initial;
    transition: 0.4s;
    white-space: pre-wrap;
    &:hover {
      // color: var(--text-secondary);
      padding-left: 16px;
      padding-right: 12px;
    }

    &--selected {
      color: var(--primary);

      &::after {
        content: "\f00c";
        color: white;
        font-family: FontAwesome;
      }

      &.multiselect__option--highlight {
        // color: var(--primary);

        &::after {
          content: "\f00d";
          color: white;
          font-family: FontAwesome;
        }
      }
    }

    &::after {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: normal;
      padding: 14px 16px;
      font-size: 16px;
    }
  }
  .multiselect__option--highlight {
    &::after {
      background: none;
    }
  }

  .multiselect__content-wrapper {
    border: 1px solid var(--input-border);
    border-radius: 5px;
  }

  .multiselect__option--group {
    color: var(--primary);
    font-weight: bold;
    &:hover {
      color: var(--text-secondary);
    }
  }

  .multiselect__option--selected.multiselect__option--highlight:after {
    background: var(--input-error-focus);
  }
  .multiselect__option--group-selected.multiselect__option--highlight:after {
    background: var(--input-error-focus);
  }
}
</style>
