<template>
  <div
    :class="`formulate-input-element formulate-input-element--${context.class}`"
    :data-type="context.type"
  >
    <div
      class="mask"
      :class="{ active: expanded }"
      @click="expanded = false"
    ></div>
    <div class="dropdown-outer">
      <!-- <div class="dropdown-mask" ></div> -->
      <div
        class="dropdown"
        ref="dropdown"
        :class="{
          active: expanded,
          error:
            !context.isValid &&
            context.showValidationErrors &&
            !context.disableErrors,
        }"
        tabindex="0"
        @click="toggle"
      >
        <div
          v-if="context.attributes.disabled || options.length === 0"
          class="input-mask"
        ></div>

        <font-awesome-icon class="icon-down" icon="angle-down" />
        <img
          v-if="displayText === 'Canada'"
          src="/img/icons/canada.png"
          alt="canada flag"
        />
        <img
          v-if="displayText === 'United States'"
          src="/img/icons/usa.png"
          alt="united states flag"
        />
        <span class="dropdown-inner-text">{{ displayText }}</span>
      </div>
      <div ref="options" class="options" :class="{ expanded }">
        <div
          class="options-inner"
          :class="{ expanded }"
          @blur="expanded = false"
        >
          <div
            class="option"
            v-for="option in options"
            :key="option.id"
            :disabled="!!option.disabled"
            v-bind="option.attributes || option.attrs || {}"
            @click="onSelect(option)"
          >
            <img
              v-if="option.label === 'Canada'"
              src="/img/icons/canada.png"
              alt="canada flag"
            />
            <img
              v-if="option.label === 'United States'"
              src="/img/icons/usa.png"
              alt="united states flag"
            />
            {{ option.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faAngleDown);

export default {
  name: "TTSelectInput",
  components: {
    FontAwesomeIcon,
  },
  props: {
    context: Object,
  },
  data() {
    return {
      mask: "(###) ### - ####",
      expanded: false,
    };
  },
  computed: {
    options() {
      return (this.context.options || []).filter((o) => !!o.value);
    },
    displayText() {
      const option = this.options.find((o) => o.value === this.context.model);

      if (option) {
        return option.label;
      }

      return this.context.attributes.placeholder;
    },
  },
  methods: {
    toggle() {
      if (this.options.length > 0) {
        this.expanded = !this.context.attributes.disabled && !this.expanded;
      }
    },
    onSelect(option) {
      if (!this.context.attributes.disabledTier) {
        this.context.model = option.value;
        this.expanded = false;
        this.context.rootEmit("selected", option.value);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  &.active {
    display: block;
  }
}

// end mask
.dropdown-outer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  width: 100%;

  // .icon-down {
  //   // position: absolute;
  //   // right: 20px;
  //   // top: 14px;
  // }

  &.inactive {
    display: none;
  }

  .dropdown-mask {
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    cursor: pointer;
  }

  .dropdown {
    display: flex;
    align-items: center;
    margin: auto;
    margin-bottom: 5px;
    padding: 12px;
    position: relative;
    // z-index: 4;
    width: 100%;
    cursor: pointer;
    border: 1px solid var(--input-border);
    border-radius: 8px;
    background-color: var(--input-background);
    font-family: "Open Sans", sans-serif;
    color: var(--text);
    font-weight: 400;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 4px;

    &.error {
      border-color: var(--error-red);
    }

    .input-mask {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      border-radius: 5px;
      background-color: rgba(82, 82, 82, 0.5);
    }

    img {
      width: 25px;
      height: 22px;
      margin-right: 10px;
    }

    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   z-index: -1;
    //   width: 100%;
    //   height: 100%;
    //   background: linear-gradient(#5f5f5f, #474747);
    //   border-radius: 8px;
    //   opacity: 0;
    //   transition: 0.4s;
    // }

    // &:hover::before {
    //   opacity: 1;
    // }

    &.active {
      z-index: 6;
      box-shadow: 0 2px 3px #00000085;
    }
    .dropdown-inner-text {
      max-width: 95%;
    }
  }
  // end dropdown
  .dropdown-small {
    display: block;
    // margin-left: auto;
    // padding-left: 5px;
    position: relative;
    z-index: 4;
    width: 100%;
    // background-color: #4c4c4c;
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#282a30),
      to(#33363d)
    );
    margin: 0 5px;
    margin-bottom: 5px;
    padding: 11px 5px;
    background-image: var(--dropdown-background);
    font-family: "Open Sans", sans-serif;
    color: var(--text);
    border: 1px solid var(--input-border);
    border-radius: 5px;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: linear-gradient(#5f5f5f, #474747);
      border-radius: 8px;
      opacity: 0;
      transition: 0.4s;
    }

    // &:hover::before {
    //   // opacity: 1;
    // }

    &.active {
      z-index: 13;
      box-shadow: 0 2px 3px #00000085;
    }
  }
  // end dropdown-small
  .options {
    position: relative;
    z-index: 12;
    width: 100%;
    // &.expanded {
    //   z-index: 12;
    // }
    .options-inner {
      position: absolute;
      z-index: 12;
      width: 100%;
      max-height: 0;
      max-width: 600px;

      background-image: var(--options-background);
      color: var(--text);
      overflow: hidden;
      border-radius: 0 0 5px 5px;
      transition: 0.4s;
      border: 1px;

      &.expanded {
        // padding-top: 10px;
        // top: -10px;
        //z-index: 5;
        max-height: 400px;
        overflow: auto;
        border: 1px solid var(--input-border);
        border-radius: 5px;
        background-image: var(--options-background);
        box-shadow: var(--dashboard-shadow);
        color: var(--text);

        .grey {
          background-color: #282727e0;
          color: #5a535375;
          pointer-events: none;
        }
      }

      .option {
        display: flex;
        align-items: center;
        padding: 12px;
        position: relative;
        transition: 0.4s;
        border-bottom: 1px solid var(--input-border);
        img {
          width: 25px;
          height: 22px;
          margin-right: 10px;
        }
        cursor: pointer;
        &:hover {
          padding-left: 16px;
          padding-right: 12px;
          background-image: var(--option-background);
        }
        &::after {
          content: "";
          margin: auto;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          width: 95%;
        }

        &.small {
          padding: 6px 8px;

          &:hover {
            padding-left: 10px;
            padding-right: 6px;
          }
        }

        &:last-child::after {
          border-bottom: none;
        }
      }
      // end option
    }
    // end option-inner
  }
  // end options
}
</style>
