<template>
  <div class="footer">
    <div class="footer-inner">
      <div class="footer-segment">
        <div class="footer-logo">
          <img
            src="@/images/logos/IncredEvent-Logo-Dark.webp"
            v-if="theme === 'dark'"
            alt="incredevent.com"
          />
          <img src="@/images/TT_Footer_Dark.svg" v-else alt="incredevent.com" />
        </div>
      </div>
      <div class="center-section">
        <div class="footer-segment">
          <h4>Sitemap</h4>
          <span v-if="currentSite === 'buyers'" @click="closeMenu">
            <router-link to="/events">
              Browse Events
            </router-link>
          </span>
          <a :href="buyers" v-else @click="closeMenu">
            Browse Events
          </a>

          <span @click="closeMenu">
            <router-link to="/about-us">
              About INCREDEVENT
            </router-link>
          </span>
          <a
            href="https://www.help.incredevent.com/feature-roadmap"
            target="_blank"
            rel="noopener noreferrer"
            >Roadmap</a
          >
          <a href="https://resources.incredevent.com" target="_blank">Blogs</a>
          <!-- <a
            href="https://help.incredevent.com/hc/en-ca/categories/6886130223515-Video-Tutorials"
            target="_blank"
            >Learn</a
          > -->
          <!-- <a
            href="https://www.help.incredevent.com/Featured_Events"
            target="_blank"
            rel="noopener noreferrer"
            >Featured Events</a
          > -->
          <span @click="closeMenu">
            <router-link v-if="!user.isAuthenticated" to="/login-register">
              Login / Register
            </router-link>
          </span>
          <!-- <router-link v-if="!user.isAuthenticated" to="/login-register"
            >Register</router-link
          > -->
          <!-- <a
            href="#"
            v-if="!user.isAuthenticated"
            class="login"
            v-on:click.prevent="loadModal('lmodal')"
            >Login</a
          >
          <a
            href="#"
            v-if="!user.isAuthenticated"
            v-on:click.prevent="loadModal('rmodal')"
            >Register</a
          > -->
          <a
            href="#"
            v-if="user.isAuthenticated"
            v-on:click.prevent="$emit('logout')"
            >Logout</a
          >
        </div>
        <div class="footer-segment">
          <h4>Assistance</h4>
          <a
            href="https://www.help.incredevent.com/faqs"
            target="_blank"
          >
            FAQ
          </a>
          <!-- <a
            href="https://help.incredevent.com/hc/en-ca/categories/6886130223515-Video-Tutorials"
            target="_blank"
            rel="noopener noreferrer"
            >Tutorials</a
          > -->
          <span @click="closeMenu">
            <router-link to="/contact-us">
              Contact Us
            </router-link>
          </span>

          <a
            href="https://www.help.incredevent.com/features"
            target="_blank"
          >
            Feature Details
          </a>
          <!-- <a
            href="https://help.incredevent.com/hc/en-ca/categories/13660585358491-Detailed-Feature-List"
            target="_blank"
          >
            Planner Features
          </a> -->
        </div>
        <div class="footer-segment">
          <h4>Legal</h4>
          <span @click="closeMenu">
            <router-link to="/terms">
              Terms of Use
            </router-link>
          </span>
          <span @click="closeMenu">
            <router-link to="/privacy-policy">
              Privacy Policy
            </router-link>
          </span>
          <span @click="closeMenu">
            <router-link to="/refunds">
              Refunds &amp; Returns
            </router-link>
          </span>
          <span @click="closeMenu">
            <router-link to="/cookies">
              Cookies Policy
            </router-link>
          </span>
          <span @click="closeMenu">
            <router-link to="/security">
              Security
            </router-link>
          </span>
        </div>
      </div>

      <div class="footer-segment">
        <div class="payment-logos">
          <!-- Dont add these in until we accept apple/google pay -->
          <!-- <div class="payment-logo">
            <img src="@/images/icons/icon-googlepay.png" alt="GooglePay" />
          </div>

          <div class="payment-logo">
            <img src="@/images/icons/icon-applepay.png" alt="GooglePay" />
          </div> -->
          <div class="column1">
            <div class="payment-logo">
              <img src="@/images/icons/credit-card/visa.png" alt="visa logo" />
            </div>
            <div class="payment-logo">
              <img
                src="@/images/icons/credit-card/mastercard.png"
                alt="mastercard logo"
              />
            </div>
            <div class="payment-logo">
              <img src="@/images/icons/credit-card/amex.png" alt="amex logo" />
            </div>
          </div>

          <div class="column2">
            <div class="payment-logo">
              <img
                src="@/images/icons/credit-card/discover.png"
                alt="discover logo"
              />
            </div>
            <div class="payment-logo">
              <img
                src="@/images/icons/credit-card/union-pay.png"
                alt="union pay logo"
              />
            </div>
          </div>

          <div class="column3">
            <div class="payment-logo">
              <img
                src="@/images/icons/credit-card/visa-debit.png"
                alt="visa debit logo"
              />
            </div>
            <div class="payment-logo">
              <img
                src="@/images/icons/credit-card/mastercard-debit.png"
                alt="mastercard debit logo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copy">
      <span>© {{ currentYear }}. All Rights Reserved.&nbsp;</span>
      <span>
        Use of this website signifies your agreement to our
        <span @click="closeMenu">
          <router-link to="/cookies">
            Cookies Policy,
          </router-link>
        </span>
        <span @click="closeMenu">
          <router-link to="/privacy-policy">
            Privacy Policy
          </router-link></span
        >and
        <span @click="closeMenu">
          <router-link to="/terms">
            Terms of Use.
          </router-link>
        </span>
        <span>INCREDEVENT is a TM held by Worre Studio</span>
      </span>
    </div>
    <!-- end footer inner -->
  </div>
</template>
<style lang="less" scoped>
.footer {
  * {
    font-family: "Roboto", sans-serif !important;
  }
  width: 100%;
  // padding-bottom: 90px;
  // color: white;
  background: @colors[navbar-background];
  box-shadow: 1px 1px 2px -1px #ffffff26, inset 1px 1px 3px 0px #00000026;
  // background-color: @colors[footer-background];

  .footer-inner {
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding: 30px 0;
    // padding-top: 10px;
    width: 94%;
    max-width: 1100px;

    .center-section {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
    }
    .footer-segment {
      padding: 10px;
      h4 {
        font-size: 21px;
        margin-bottom: 8px;
        margin-top: 0;
        // margin-bottom: 0.5em;
      }

      a {
        display: block;
        font-size: 13px;
        color: var(--text);
        text-align: left;
        margin: 7px 0;
        word-break: break-word;
        // padding: ;

        &:hover {
          color: var(--primary);
        }
      }

      .footer-logo {
        // margin-top: -1px;
        width: 140px;

        img {
          display: block;
          max-width: 100%;
          width: 100%;
        }
      } // end footer-logo

      .payment-logos {
        // margin-top: 1em;
        display: flex;
        flex-direction: column;
        // display: flex;
        // flex-direction: column;

        .payment-logo {
          margin: 8px 0;
          width: 50px;

          img {
            display: block;
            max-width: 100%;
          }
        }

        .column1 {
          display: flex;
        }
        .column2 {
          display: flex;
          justify-content: space-evenly;
        }
        .column3 {
          display: flex;
          justify-content: space-evenly;
        }
        .payment-logo.e {
          grid-column: 2;
        }
      } // end payment-logos

      // @media screen and (max-width: 540px) {
      //   padding: ;
      //   // margin: auto;
      //   // width: 96%;
      // }
    } // end footer-segment
    @media screen and (min-width: 1200px) {
      max-width: 1100px;
    }
    @media screen and (max-width: 1200px) {
      // max-width: 90%;
    }
    @media screen and (max-width: 800px) {
      flex-wrap: wrap;
      justify-content: center;

      .center-section {
        justify-content: space-between;
      }

      .footer-segment {
        h4 {
          font-size: 17px;
        }
        .payment-logos {
          // grid-template-rows: 1fr;
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex-direction: row;
          grid-column-gap: 0;
          .payment-logo {
            margin: 0 5px;
            width: 40px;
          }
        }
      }
    }
  } // end footer-inner

  .copy {
    padding: 20px;
    display: flex;
    justify-content: center;
    font-size: 12px;
    border-top: 1px solid #41454d;
    // box-shadow: inset 1px 0px 10px -5px black;
    background: @colors[navbar-background];
    // color: #697e8a;
    flex-wrap: wrap;

    a {
      color: var(--primary);
      display: contents;
    }
    span {
      text-align: center;
    }
  }

  @media screen and (max-width: 730px) {
    padding-bottom: 90px;
    display: none;
  }
} // end footer
</style>
<script>
export default {
  name: "Footer",
  methods: {
    closeMenu() {
      this.$emit("closeMenu");
    },
  },
  computed: {
    theme() {
      return this.$store.state.theme;
    },
    currentSite() {
      return this.$store.state.site;
    },
    buyers() {
      switch (process.env.VUE_APP_ENVIRONMENT) {
        case "development":
          return "http://localhost:3100/events";
        case "staging":
          return "https://staging.incredevent.com/events";
        default:
          return "https://incredevent.com/events";
      }
    },
    covid() {
      switch (process.env.VUE_APP_ENVIRONMENT) {
        case "development":
          return "http://localhost:3200/covid-relief";
        case "staging":
          return "https://planners.staging.incredevent.com/covid-relief";
        default:
          return "https://planners.incredevent.com/covid-relief";
      }
    },
    user() {
      return this.$store.state.user;
    },
    currentYear() {
      let date = new Date();
      return date.getFullYear();
    },
  },
};
</script>
