<template>
  <div
    :class="`formulate-input-element formulate-input-element--${context.class}`"
    :data-type="context.type"
  >
    <input
      type="checkbox"
      :value="context.value"
      v-model="context.model"
      v-bind="context.attributes"
    />
    <component
      :is="`label`"
      :class="context.classes.decorator"
      :for="context.attributes.id"
    />
  </div>
</template>

<script>
export default {
  name: "TTCheckboxInput",
  props: {
    context: Object
  },
  methods: {
    onCheck() {
      this.context.model = !this.context.model;
    }
  }
};
</script>
