<template>
  <div
    :class="`formulate-input-element formulate-input-element--${context.class}`"
    :data-type="context.type"
  >
    <div class="mask" :class="{ active: expanded }" v-on:click="close()"></div>
    <div class="dropdown-outer">
      <div
        class="dropdown"
        ref="dropdown"
        :class="{
          active: expanded,
          error: !context.isValid && context.showValidationErrors,
        }"
        v-on:click="expand()"
      >
        <div
          v-if="context.attributes.disabled || options.length === 0"
          class="input-mask"
        ></div>
        <div class="icon-down">
          <font-awesome-icon icon="angle-down" />
        </div>
        {{ displayText }}
      </div>
      <div class="options" ref="options" :class="{ expanded }">
        <div class="options-inner" :class="{ expanded }">
          <div
            class="option"
            v-for="option in options"
            :key="option.id"
            :class="{ selected: value.includes(option.value) }"
            v-bind="option.attributes || option.attrs || {}"
            v-on:click="check(option)"
          >
            {{ option.label }}
            <div v-if="value.includes(option.value)" class="check-icon">
              <font-awesome-icon icon="check" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faAngleDown, faCheck);

export default {
  name: "TTMultiSelectInput",
  components: {
    FontAwesomeIcon,
  },
  props: {
    context: Object,
    useCountLabel: Boolean,
    bundle: Boolean,
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    options() {
      return (this.context.options || []).filter((o) => !!o.value);
    },
    displayText() {
      const placeholder = this.context.attributes.placeholder;

      if (this.useCountLabel) {
        return `${this.value.length} Selected`;
      }

      if (this.bundle && this.value.length > 0) {
        let labelArray = [];
        this.value.forEach((val) => {
          let newValue = this.options.find((option) => option.value === val);
          labelArray.push(newValue.label);
        });
        return labelArray.join(", ");
      } else if (this.value.length > 0) {
        return this.options
          .filter((o) => this.value.includes(o.value))
          .map((o) => o.label)
          .join(", ");
      }

      return placeholder;
    },
    value: {
      get() {
        if (!Array.isArray(this.context.model)) {
          return [];
        }

        return this.context.model;
      },
      set(value) {
        this.context.model = value;
      },
    },
  },
  methods: {
    expand() {
      if (this.options.length > 0 && !this.context.attributes.disabled) {
        if (this.expanded) {
          let dropdown = this.$refs.dropdown;
          let options = this.$refs.options;
          dropdown.style.zIndex = 6;
          options.style.zIndex = 5;
          this.expanded = false;
          setTimeout(() => {
            dropdown.removeAttribute("style");
            options.removeAttribute("style");
          }, 400);
        } else this.expanded = true;
      }
    },
    close() {
      let dropdown = this.$refs.dropdown;
      let options = this.$refs.options;
      dropdown.style.zIndex = 6;
      options.style.zIndex = 5;
      this.expanded = false;
      setTimeout(() => {
        dropdown.removeAttribute("style");
        options.removeAttribute("style");
      }, 400);
    },
    check(option) {
      if (this.value.includes(option.value)) {
        this.value = this.value.filter((o) => o !== option.value);
      } else {
        this.value = [...this.value, option.value];
      }
    },
  },
};
</script>

<style lang="less" scoped>
label {
  display: block;
  flex-basis: 100%;
  margin-bottom: 0.5em;
  font-size: 16px;
  font-weight: 200;

  b {
    padding-left: 2px;
    color: @colors[input-required];
  }
}

.input-mask {
  position: absolute;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
  border-radius: 5px;
  background-color: rgba(82, 82, 82, 0.5);
}

.mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.active {
    display: block;
  }
}

.dropdown-outer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  width: 100%;

  &.inactive {
    display: none;
  }

  .dropdown {
    display: flex;
    align-items: center;
    margin: auto;
    margin-bottom: 5px;
    padding: 12px; //17px
    position: relative;
    z-index: 4;
    width: 100%;
    cursor: pointer;
    border: 1px solid var(--input-border);
    border-radius: 8px;
    // background-image: -webkit-gradient(
    //   linear,
    //   left bottom,
    //   left top,
    //   from(#282a30),
    //   to(#33363d)
    // );
    background-color: var(--input-background);
    font-family: "Open Sans", sans-serif;
    color: var(--text);
    font-weight: 400;

    &.error {
      border-color: var(--error-red);
    }

    .icon-down {
      position: absolute;
      right: 20px;
      top: 14px;
    }

    img {
      width: 25px;
      height: 22px;
      margin-right: 10px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      // background: var(--dropdown-background);
      border-radius: 8px;
      opacity: 0;
      transition: 0.4s;
    }

    &:hover::before {
      opacity: 1;
    }

    &.active {
      z-index: 6;
      box-shadow: 0 2px 3px #00000085;
    }
  }
  // end dropdown
  .dropdown-small {
    display: block;
    margin-left: auto;
    padding-left: 5px;
    position: relative;
    z-index: 4;
    width: 100%;
    background: var(--options-background);
    border: 1px solid #4c4c4c;
    border-radius: 8px;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: linear-gradient(#5f5f5f, #474747);
      border-radius: 8px;
      opacity: 0;
      transition: 0.4s;
    }

    &:hover::before {
      opacity: 1;
    }

    &.active {
      z-index: 6;
      box-shadow: 0 2px 3px #00000085;
    }
  }
  // end dropdown-small
  .options {
    position: relative;
    z-index: 12;
    width: 100%;
    // &.expanded {
    //   z-index: 12;
    // }
    .options-inner {
      position: absolute;
      z-index: 12;
      width: 100%;
      max-height: 0;
      max-width: 600px;

      background-image: var(--options-background);
      color: var(--text);
      overflow: hidden;
      border-radius: 0 0 5px 5px;
      transition: 0.4s;
      border: 1px;

      &.expanded {
        // padding-top: 10px;
        // top: -10px;
        //z-index: 5;
        max-height: 400px;
        overflow: auto;
        box-shadow: 0px 8px 20px -6px #00000080;

        border: 1px solid var(--input-border);
        border-radius: 5px;

        background-image: var(--options-background);
        box-shadow: var(--dashboard-shadow);
        color: var(--text);

        .grey {
          background-color: #282727e0;
          color: #5a535375;
          pointer-events: none;
        }
      }
      .option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 14px;
        position: relative;
        transition: 0.4s;
        border-bottom: 1px solid var(--input-border);
        cursor: pointer;

        img {
          width: 25px;
          height: 22px;
          margin-right: 10px;
        }

        &.selected {
          background-color: var(--options-background);
        }

        &:hover {
          padding-left: 16px;
          padding-right: 12px;
          background-image: var(--options-background);
        }

        &::after {
          content: "";
          margin: auto;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          width: 95%;
        }

        &:last-child::after {
          border-bottom: none;
        }
      }
      // end option
    }
    // end option-inner
  }
  // end options
}

.error-msg {
  position: relative;
  width: 100%;
  top: -1px;
  margin: 0 6px;
  border: 1px solid #1a0306;
  border-top: 1px solid transparent;
  border-radius: 0 0 5px 5px;
  background: #4e2e33;
  transition: 0.4s;

  .error-msg-inner {
    color: white;
    padding: 2px 5px 4px 5px;

    .icon {
      padding-right: 5px;
    }
  }
}

&.error {
  label {
    color: #e60022;
  }
}
</style>
