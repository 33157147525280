import ModalContent from "@/helpers/modals";
import axios from "axios";
import decode from "jwt-decode";
import {
  getLocationCookie,
  setLocationCookie
} from "../../../shared/helpers/location";

export const SESSION_COOKIE =
  process.env.VUE_APP_ENVIRONMENT === "staging" ? "ssession" : "session";

export function getCookies() {
  let cookie = document.cookie.split(";");
  let cookies = {};

  cookie.forEach(key => {
    let keyVal = key.trim().split("=");
    cookies[keyVal[0]] = keyVal[1];
  });

  return cookies;
}

export function getSessionCookie() {
  return getCookies()[SESSION_COOKIE];
}

export async function createSession(username, password, app, admin) {
  let data;
  let dataInfo;
  if (admin) {
    await axios
      .post("/auth/authenticate", {
        name: username,
        password: "",
        admin: admin
      })
      .then(resp => {
        data = resp.data;
      });
  } else {
    await axios
      .post("/auth/authenticate", {
        name: username,
        password: password
      })
      .then(resp => {
        data = resp.data;
      })
      .catch(({ response }) => {
        throw response.data.error;
      });
  }
  if (data.error) throw data.error;
  else {
    dataInfo = decode(data.token);
    if (dataInfo.activated) {
      setSessionCookie(data.token);

      let location = data.user.location;
      if (location && !getLocationCookie()) {
        if (location.split("-").length === 3) {
          location = location.replace(/\-/g, "|");
        }

        const parts = location.split("|");

        if (parts.length === 3) {
          setLocationCookie(parts[0], parts[1], parts[2]);
        }
      }

      app.$store.commit("setUser", data.user);
      app.$store.commit("setData", dataInfo);
    } else {
      // throw Error;
      app.$store.state.bus.$emit("alert", ModalContent.accountNotActivated);
      app.$bus.$emit("redirectVerification", data.user._id);
    }
  }
}

export async function adminUserSession(id, app) {
  let data;
  let dataInfo;
  await axios
    .post("/auth/authenticate-admin-as-user", {
      id: id
    })
    .then(resp => {
      data = resp.data;
    });

  if (data.error) throw data.error;
  else {
    dataInfo = decode(data.token);
    if (dataInfo.activated) {
      setSessionCookie(data.token);

      app.$store.commit("setUser", data.user);
      app.$store.commit("setData", dataInfo);
    } else {
      // throw Error;
      app.$store.state.bus.$emit("alert", ModalContent.accountNotActivated);
    }
  }
}

export function destroySession(app) {
  app.$axios.post("/auth/deauthenticate").then(() => {
    app.$store.dispatch("logout");

    switch (process.env.VUE_APP_ENVIRONMENT) {
      case "development":
        document.cookie = `${SESSION_COOKIE}=${getSessionCookie()};path=/;domain=localhost;expires=Thu, 18 Dec 2013 12:00:00 UTC`;
        break;
      case "staging":
        document.cookie = `${SESSION_COOKIE}=${getSessionCookie()};path=/;domain=.staging.incredevent.com;expires=Thu, 18 Dec 2013 12:00:00 UTC`;
        break;
      default:
        document.cookie = `${SESSION_COOKIE}=${getSessionCookie()};path=/;domain=.incredevent.com;expires=Thu, 18 Dec 2013 12:00:00 UTC`;
        break;
    }
  });
}

export function updateSession(app, callback) {
  let cookies = getCookies();
  let data = decode(cookies[SESSION_COOKIE]);
  let dataInfo;
  let respData;

  axios
    .post("/auth/update-session", {
      session: data.session_id,
      email: app.emailInput
    })
    .then(resp => {
      respData = resp.data;
      // get session data and store it in app data
      dataInfo = decode(respData.token);

      if (dataInfo.activated) {
        setSessionCookie(respData.token);

        // document.cookie = `session=${dataInfo}`;
        app.$store.commit("setUser", respData.user);
        app.$store.commit("setData", dataInfo);
      } else {
        // throw Error;
        app.$store.state.bus.$emit("alert", ModalContent.accountNotActivated);
      }

      if (callback) callback();
    });
}

export function setAuthToken() {}

export function getAuthToken() {
  let cookies = getCookies();
  let session = cookies[SESSION_COOKIE] ? cookies[SESSION_COOKIE] : false;

  return session;
}

export function setSessionCookie(session) {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 7);
  switch (process.env.VUE_APP_ENVIRONMENT) {
    case "development":
      document.cookie = `${SESSION_COOKIE}=${session};path=/;domain=localhost;expires=${expirationDate.toUTCString()};`;
      break;
    case "staging":
      document.cookie = `${SESSION_COOKIE}=${session};path=/;domain=.staging.incredevent.com;expires=${expirationDate.toUTCString()};`;
      break;
    default:
      document.cookie = `${SESSION_COOKIE}=${session};path=/;domain=.incredevent.com;expires=${expirationDate.toUTCString()};`;
      break;
  }
}

export function clearAuthToken() {}

export function isAuthenticated() {
  let cookies = getCookies();
  if (cookies) {
    try {
      let decoded = decode(cookies[SESSION_COOKIE]);
      let authenticated = decoded.session_id;

      if (authenticated) {
        return true;
      } else {
        return false;
      }
    } catch (error) {}
    // console.log(decoded);
  }
}

export async function isCoordinator() {
  // await axios.get("/account/verify").then((resp) => {
  //   let data = resp.data;
  //   console.log(data);
  // });

  let cookies = getCookies();
  if (cookies) {
    try {
      let decoded = decode(cookies[SESSION_COOKIE]);
      let coordinator = decoded.coordinator;
      return coordinator;
    } catch (error) {}
  }
}
