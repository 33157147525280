<template>
  <modal
    class="alert"
    :active="active"
    :title="title"
    :confirm="confirm"
    :closable="closable"
    v-on:close="close"
  >
    <div v-html="msg"></div>
    <!-- {{ msg }}
    <template v-slot:text>
    <div class="text" v-if="link">

    </div>
    </template> -->
    <template v-slot:footer>
      <diyobo-button
        type="secondary"
        :txt="cancelBtn"
        @click="close"
        v-show="closable"
      />
      <diyobo-button type="primary" :txt="confirm" v-on:click="okay" />
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";

export default {
  name: "alert-modal",
  components: {
    Modal,
    DiyoboButton
  },
  props: {
    link: Boolean,
    closable: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      title: "",
      msg: "",
      confirm: "Okay",
      active: false,
      onConfirm: null,
      onClose: null,
      cancelBtn: "Cancel"
    };
  },
  methods: {
    open(args) {
      this.active = true;
      this.title = args.title || "Heads Up";
      this.msg = args.msg;
      this.confirm = args.confirm || "Okay";
      this.cancelBtn = args.cancelBtn || "Cancel";
      this.onConfirm = args.onConfirm;
      this.onClose = args.onClose || null;
      this.closable = args.closable || true;
      document.body.style.overflow = "hidden";
    },
    close(ok) {
      if (this.onClose && !ok) this.onClose();
      this.active = false;
      document.body.style.overflow = "initial";
    },
    okay() {
      if (this.onConfirm) this.onConfirm();
      this.close(true);
    }
  }
};
</script>

<style lang="less" scoped>
.alert {
  z-index: 1001;

  .modal-footer {
    button {
      margin-left: 16px;
    }
  }
}
</style>
