import axios from "axios";
import decode from "jwt-decode";
import Vue from "vue";
import Vuex from "vuex";
import { getSystemTheme, setThemeCookie } from "@/helpers/theme";

import {
  getCookies,
  getSessionCookie,
  SESSION_COOKIE
} from "../helpers/auth.js";
import guestlistCart from "./modules/guestlistCart";

axios.defaults.baseURL = process.env.VUE_APP_SERVICE_URL.slice(0, -1);
axios.interceptors.request.use(
  config => {
    config.headers["Authorization"] = `Bearer ${getSessionCookie()}`;
    return config;
  },
  error => Promise.reject(error)
);
Vue.use(Vuex);

export default new Vuex.Store({
  modules: { guestlistCart },
  state: {
    currency: {
      name: "CAD",
      value: 1
    },
    cadCurrencyOption: null,
    usdCurrencyOption: null,
    helpVideoSrc: null,
    site: "planners",
    session_id: null,
    theme: "dark",
    user: { isAuthenticated: false },
    activeBrand: null,
    count: 0,
    loading: false,
    creating: false,
    authenticated: false,
    bus: new Vue(),
    title: "",
    subtitle: ""
  },
  mutations: {
    setHelpVideoSrc(state, payload) {
      state.helpVideoSrc = payload;
    },
    setCurrency(state, payload) {
      state.currency = payload;
    },
    setCadCurrencyOption(state, payload) {
      state.cadCurrencyOption = payload;
    },
    setUsdCurrencyOption(state, payload) {
      state.usdCurrencyOption = payload;
    },
    setTheme(state, theme) {
      if (theme) {
        if (theme === "system") {
          state.theme = getSystemTheme();
        } else {
          state.theme = theme;
        }

        setThemeCookie(theme);
      }
    },
    updateProfileImg: (state, data) => {
      state.user.profile_img = data;
    },
    setUser(state, data) {
      state.user = data;
      state.user.isAuthenticated = true;
      state.user.name = data.first_name + " " + data.last_name;
      state.user.upcomingTickets = data.upcomingTickets;
    },
    updateUserProperty: (state, obj) => {
      state.user[obj.property] = obj.value;
    },
    setData(state, data) {
      state.session_id = data.session_id;
      state.user.activated = data.activated;
      state.profileComplete = data.profileComplete;
      state.user.coordinator = data.coordinator;
      state.profileComplete = data.profileComplete;
    },
    //TODO improve logout
    setLogout(state, data) {
      state.user = { isAuthenticated: false };
      state.session_id = null;
      if ($cookies.isKey("currency")) {
        $cookies.remove("currency");
      }
    },
    setUserName(state, data) {
      state.user = {
        ...state.user,
        first_name: data.firstName,
        last_name: data.lastName,
        name: data.firstName + " " + data.lastName
      };
    },
    setBrand(state, data) {
      console.log('set brand', data);
      if (data) {
        sessionStorage.activeBrandName = data.name;
        sessionStorage.activeBrandSubdomain = data.subdomain;
        state.activeBrand = data;
        state.subtitle = data.name;
        state.user.activeBrand = data;
      } else {
        sessionStorage.removeItem("activeBrandName");
        sessionStorage.removeItem("activeBrandSubdomain");
        state.activeBrand = null;
        state.subtitle = "";
        state.user.activeBrand =null;
      }
    },
    setTitle(state, title) {
      state.title = title;
    },
    setSubtitle(state, subtitle) {
      state.subtitle = subtitle;
    }
  },
  actions: {
    updateHelpVideoSrc(state, payload) {
      state.commit("setHelpVideoSrc", payload);
    },
    updateCurrency(state, payload) {
      state.commit("setCurrency", payload);
    },
    getCadCurrencyOption(state, payload) {
      state.commit("setCadCurrencyOption", payload);
    },
    getUsdCurrencyOption(state, payload) {
      state.commit("setUsdCurrencyOption", payload);
    },
    logout({ commit }) {
      commit("setLogout", null);
      commit("setBrand", null);
    },
    toggleTheme({ commit, state }) {
      commit("setTheme", state.theme === "dark" ? "light" : "dark");
    },

    async changeBrand({ commit, state }, data) {
      commit("setBrand", data);
      axios.post("/account/update-active-brand", { activeBrand: data });
    },
    async loadUser({ commit, state }) {
      let cookies = getCookies();
      const decoded = decode(cookies[SESSION_COOKIE]);

      axios.post("/auth/verify").then(({ data }) => {
        commit("setUser", data.user);
        commit("setData", decoded);
      });
    }
  },
  getters: {
    getSelectedGuestlistItems(state) {
      return event =>
        state.guestlistCart.guestlist_cart.filter(p => p.event_url === event);
    },
    getGuestlistForm(state) {
      return () => state.guestlistCart.guestlist;
    },
    getIsAuth: state => {
      return state.user.isAuthenticated;
    },
    isVerified(state) {
      return state.user.verificationInfo;
    },
    getUser(state) {
      return function() {
        return state.user.email;
      };
    },
    anyBrand(state) {
      return state.user.plannerBrands;
    },
    getStepperProperties(state) {
      return function() {
        return state.user.email;
      };
    }
  }
});
