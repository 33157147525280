import { render, staticRenderFns } from "./TTSelectInput.vue?vue&type=template&id=06b8852c&scoped=true&"
import script from "./TTSelectInput.vue?vue&type=script&lang=js&"
export * from "./TTSelectInput.vue?vue&type=script&lang=js&"
import style0 from "./TTSelectInput.vue?vue&type=style&index=0&id=06b8852c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06b8852c",
  null
  
)

export default component.exports