import Vue from "vue";
import Router from "vue-router";
import { isAuthenticated } from "./helpers/auth.js";
import store from "./store/store";

Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const router = new Router({
  mode: "history",
  base:
    process.env.NODE_ENV === "development"
      ? process.env.BASE_URL + "planners"
      : process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: () =>
        import(/* webpackChunkName: "home" */ "./views/Home.vue"),
      meta: {
        allowAnonymous: true
      }
    },
    {
      path: "/convert",
      name: "convert",
      component: () =>
        import(/* webpackChunkName: "convert" */ "./views/Convert.vue"),
      meta: {
        allowAnonymous: true
      }
    },
    {
      path: "/refund/:id?",
      component: () => import("./views/refund/_id.vue"),
      meta: { allowAnonymous: true },
      name: "refund-id"
    },
    {
      path: "/about-us",
      name: "about-us",
      component: () =>
        import(/* webpackChunkName: "about-us" */ "@/views/About.vue"),
      meta: {
        allowAnonymous: true
      }
    },
    {
      path: "/rates",
      name: "rates",
      component: () =>
        import(/* webpackChunkName: "rates" */ "./views/Rates.vue"),
      meta: {
        allowAnonymous: true
      },
      children: [
        {
          path: "",
          name: "ticketing",
          redirect: "ticketing"
        },
        {
          path: "ticketing",
          component: () =>
            import(
              /* webpackChunkName: "ticketing" */ "./views/Rates/Ticketing.vue"
            ),
          meta: {
            allowAnonymous: true
          }
        },
        {
          path: "guest-list",
          component: () =>
            import(
              /* webpackChunkName: "guest-list" */ "./views/Rates/GuestList.vue"
            ),
          meta: {
            allowAnonymous: true
          }
        }
      ]
    },
    {
      path: "/faq",
      name: "faq",
      component: () =>
        import(/* webpackChunkName: "help-center" */ "@/views/FAQ.vue"),
      meta: {
        allowAnonymous: true
      }
    },
    {
      path: "/contact-us",
      name: "contact-us",
      component: () =>
        import(/* webpackChunkName: "contact-us" */ "@/views/Contact.vue"),
      meta: {
        allowAnonymous: true
      }
    },
    {
      path: "/terms",
      name: "terms",
      component: () =>
        import(/* webpackChunkName: "terms" */ "@/views/Terms.vue"),
      meta: {
        allowAnonymous: true
      }
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: () =>
        import(
          /* webpackChunkName: "privacy-policy" */
          "@/views/Privacy.vue"
        ),
      meta: {
        allowAnonymous: true
      }
    },
    {
      path: "/refunds",
      name: "refunds",
      component: () =>
        import(/* webpackChunkName: "refunds" */ "@/views/Refunds.vue"),
      meta: {
        allowAnonymous: true
      }
    },
    {
      path: "/cookies",
      name: "cookies",
      component: () =>
        import(/* webpackChunkName: "cookies" */ "@/views/Cookies.vue"),
      meta: {
        allowAnonymous: true
      }
    },
    {
      path: "/profile",
      name: "profile",
      component: () =>
        import(/* webpackChunkName: "profile" */ "@/views/Profile.vue")
    },
    {
      path: "/brand-stats",
      component: () =>
        import(
          /* webpackChunkName: "brand-stats" */
          "./views/companyStats.vue"
        ),
      meta: {
        allowAnonymous: true
      }
    },
    {
      path: "/id-protection",
      name: "identity-protection",
      component: () =>
        import(
          /* webpackChunkName: "id-protection" */
          "./views/IdentityProtection.vue"
        )
    },
    {
      path: "/reserved-seats/:url",
      name: "reserved-seats",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "id-protection" */
          "./views/Events/ReservedSeats.vue"
        )
    },
    {
      path: "/manage-reserved-seats/:url",
      name: "reserved-seats",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "id-protection" */
          "./views/Events/ManageReservedSeats.vue"
        )
    },
    // {
    //   path: "/brand-settings",
    //   name: "brand-settings",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "brand-settings" */
    //       "./views/brandSettings.vue"
    //     )
    // },
    {
      path: "/brand-page/:subdomain",
      name: "brand-page",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "brand-page" */
          "./views/BrandPage.vue"
        )
    },
    {
      path: "/security",
      name: "security",
      component: () =>
        import(/* webpackChunkName: "security" */ "@/views/Security.vue"),
      meta: {
        allowAnonymous: true
      }
    },
    {
      path: "/create-event",
      component: () =>
        import(
          /* webpackChunkName: "create-event" */
          "./views/CreateEvent.vue"
        ),

      children: [
        {
          path: "",
          name: "create-event",
          redirect: "config"
        },
        {
          path: "config",
          component: () =>
            import(
              /* webpackChunkName: "create-event" */ "./components/cec/1Config.vue"
            )
        },
        {
          path: "dates",
          component: () =>
            import(
              /* webpackChunkName: "create-event" */ "./components/cec/2Date.vue"
            )
        },
        {
          path: "venue",
          component: () =>
            import(
              /* webpackChunkName: "create-event" */ "./components/cec/3Venue.vue"
            )
        },
        {
          path: "images",
          component: () =>
            import(
              /* webpackChunkName: "create-event" */ "./components/cec/4Images.vue"
            )
        },
        {
          path: "details",
          component: () =>
            import(
              /* webpackChunkName: "create-event" */ "./components/cec/5Details.vue"
            )
        },
        {
          path: "tiers",
          component: () =>
            import(
              /* webpackChunkName: "create-event" */ "./components/cec/6TicketTiers.vue"
            )
        },
        {
          path: "products",
          component: () =>
            import(
              /* webpackChunkName: "create-event" */ "./components/cec/7Products.vue"
            )
        },
        {
          path: "terms",
          component: () =>
            import(
              /* webpackChunkName: "create-event" */ "./components/cec/8PlannerTerms.vue"
            )
        },
        {
          path: "submit",
          component: () =>
            import(
              /* webpackChunkName: "create-event" */ "./components/cec/9Submit.vue"
            )
        }
      ]
    },

    // {
    //   path: "/resources",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "resources" */
    //       "./views/Resources.vue"
    //     ),
    //   meta: {
    //     allowAnonymous: true
    //   }
    // },

    {
      path: "/resources",
      name: "resources",

      component: () =>
        import(/* webpackChunkName: "resources" */ "./views/Blog/Resources.vue")
    },
    {
      path: "/resources/:articleLink",
      name: "blog-list",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "resources"  */
          "./views/Blog/BlogList.vue"
        )
    },
    {
      path: "/resources/:articleLink/:slug",
      name: "blog",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "resources"  */
          "./views/Blog/Blog.vue"
        )
    },
    {
      path: "/wedding-socials",
      component: () =>
        import(
          /* webpackChunkName: "wedding-socials" */
          "./views/WeddingSocial.vue"
        ),
      meta: {
        allowAnonymous: true
      }
    },
    {
      path: "/wedding-form",
      component: () =>
        import(
          /* webpackChunkName: "form" */
          "./views/WeddingSocial/Form.vue"
        ),
      meta: {
        allowAnonymous: true
      }
    },

    {
      path: "/edit-event/:url",
      component: () =>
        import(/* webpackChunkName: "edit-event" */ "./views/EditEvent.vue"),
      children: [
        {
          path: "",
          name: "edit-event",
          redirect: "config"
        },
        {
          path: "country",
          redirect: "config"
        },
        {
          path: "config",
          component: () =>
            import(
              /* webpackChunkName: "edit-event" */ "./components/cec/1Config.vue"
            )
        },
        {
          path: "name",
          redirect: "dates"
        },
        {
          path: "dates",
          component: () =>
            import(
              /* webpackChunkName: "edit-event" */ "./components/cec/2Date.vue"
            )
        },
        {
          path: "location",
          redirect: "venue"
        },
        {
          path: "venue",
          component: () =>
            import(
              /* webpackChunkName: "edit-event" */ "./components/cec/3Venue.vue"
            )
        },
        {
          path: "images",
          component: () =>
            import(
              /* webpackChunkName: "edit-event" */ "./components/cec/4Images.vue"
            )
        },
        {
          path: "info",
          redirect: "details"
        },
        {
          path: "details",
          component: () =>
            import(
              /* webpackChunkName: "edit-event" */ "./components/cec/5Details.vue"
            )
        },
        {
          path: "tiers",
          component: () =>
            import(
              /* webpackChunkName: "edit-event" */ "./components/cec/6TicketTiers.vue"
            )
        },
        {
          path: "products",
          component: () =>
            import(
              /* webpackChunkName: "edit-event" */ "./components/cec/7Products.vue"
            )
        },
        {
          path: "sections",
          redirect: "terms"
        },
        {
          path: "terms",
          component: () =>
            import(
              /* webpackChunkName: "edit-event" */ "./components/cec/8PlannerTerms.vue"
            )
        },
        {
          path: "tos",
          redirect: "submit"
        },
        {
          path: "submit",
          component: () =>
            import(
              /* webpackChunkName: "edit-event" */ "./components/cec/9Submit.vue"
            )
        }
      ]
    },
    // {
    //   path: "/comps/:url",
    //   name: "comps",
    //   component: () =>
    //     import(/* webpackChunkName: "comps" */ "./views/Comps.vue")
    // },

    {
      path: "/instant-email/:url",
      name: "instant-email",
      props: true,
      redirect: "/emails/:url"
    },
    {
      path: "/about-automation",
      name: "about-automation",
      component: () =>
        import(
          /* webpackChunkName: "about-automation" */
          "./views/Automation/AboutAutomation.vue"
        )
    },
    {
      path: "/automated-email",
      name: "automated-email",
      component: () =>
        import(
          /* webpackChunkName: "automated-emails" */
          "./views/AutomatedEmails.vue"
        )
    },
    {
      path: "/how-it-works",
      name: "how-it-works",
      component: () =>
        import(/* webpackChunkName: "how-it-works" */ "@/views/HowItWorks.vue"),
      props: true,
      meta: {
        allowAnonymous: true
      }
    },
    {
      path: "/accessibility-statement",
      name: "accessibility-statement",
      component: () =>
        import(
          /* webpackChunkName: "components" */
          "@/views/AccessibilityStatement.vue"
        ),
      meta: {
        allowAnonymous: true
      }
    },
    // {
    //   path: "/blog",
    //   name: "blog",
    //   component: () =>
    //     import(/* webpackChunkName: "blog" */ "@/views/Blog.vue"),
    //   meta: {
    //     allowAnonymous: true
    //   }
    // },
    {
      path: "/planner-features",
      name: "planner-features",
      component: () =>
        import(
          /* webpackChunkName: "planner-features" */
          "@/views/PlannerFeatures.vue"
        ),
      meta: {
        allowAnonymous: true
      }
    },
    {
      path: "/buyer-features",
      name: "buyer-features",
      component: () =>
        import(
          /* webpackChunkName: "PVFinancialsCode.vue" */
          "@/views/BuyerFeatures.vue"
        ),
      meta: {
        allowAnonymous: true
      }
    },
    {
      path: "/financials/:subdomain/:event?",
      name: "financials",
      // props: true,
      component: () =>
        import(/* webpackChunkName: "financials" */ "./views/Financials.vue")
    },
    {
      path: "/my-events",
      name: "my-events",
      redirect: "/dashboard"
    },
    {
      path: "/print/:url",
      name: "print",
      component: () =>
        import(/* webpackChunkName: "print" */ "./views/Print.vue")
    },
    // {
    //   path: "/promo-codes/:url",
    //   name: "promo-codes",
    //   component: () =>
    //     import(/* webpackChunkName: "promo-codes" */ "./views/Discounts/PromoCodes.vue")
    // },
    {
      path: "/discounts/:url",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "promo-codes" */ "./views/Discounts/Index.vue"
        ),
      children: [
        {
          path: "",
          name: "promo-codes",
          redirect: "promo-codes"
        },
        {
          path: "promo-codes",
          component: () =>
            import(
              /* webpackChunkName: "promo-codes" */ "./views/Discounts/PromoCodes.vue"
            )
        },
        {
          path: "bulk-discounts",
          component: () =>
            import(
              /* webpackChunkName: "bulk-discounts" */ "./views/Discounts/BulkDiscounts.vue"
            )
        },
        {
          path: "bundle-discounts",
          component: () =>
            import(
              /* webpackChunkName: "bundle-discounts" */
              "./views/Discounts/BundleDiscounts.vue"
            )
        }
      ]
    },
    {
      path: "/comps/:url",
      props: true,
      name: "comps",
      component: () =>
        import(/* webpackChunkName: "comps" */ "./views/Comps.vue"),

      children: [
        {
          path: "",
          name: "manual-entry",
          redirect: "manual-entry"
        },
        {
          path: "manual-entry",
          component: () =>
            import(
              /* webpackChunkName: "manual-entry" */ "./views/Comps/ManualEntry.vue"
            )
        },
        {
          path: "bulk-upload",
          component: () =>
            import(
              /* webpackChunkName: "bulk-upload" */ "./views/Comps/BulkUpload.vue"
            )
        }
      ]
    },
    {
      path: "/tote-swap",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "tote-swap" */ "@/views/tote-swap/Index.vue"
        ),
      children: [
        {
          path: "",
          name: "tote-swap",
          redirect: "upgrade"
        },
        {
          path: "upgrade",
          name: "upgrade",
          component: () =>
            import(
              /* webpackChunkName: "upgrade" */
              "@/views/tote-swap/Upgrade.vue"
            )
        },
        {
          path: "exchange",
          name: "exchange",
          component: () =>
            import(
              /* webpackChunkName: "exchange" */
              "@/views/tote-swap/Exchange.vue"
            )
        },
        {
          path: "refund",
          name: "refund",
          component: () =>
            import(
              /* webpackChunkName: "refund" */
              "@/views/tote-swap/Refund.vue"
            )
        }
      ]
    },
    {
      path: "/guestlist/:url",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "guestlist" */ "./views/GuestList/Index.vue"
        ),
      children: [
        {
          path: "",
          name: "guestlist",
          redirect: "add-to-list"
        },
        {
          path: "add-to-list",
          name: "guestlist-add-to-list",
          component: () =>
            import(
              /* webpackChunkName: "guestlist-addtolist" */
              "./views/GuestList/AddToList.vue"
            )
        },
        {
          path: "view-list",
          name: "guestlist-view-list",
          component: () =>
            import(
              /* webpackChunkName: "guestlist-viewlist" */
              "./views/GuestList/ViewList.vue"
            )
        },
        {
          path: "rankings",
          name: "guestlist-rankings",
          component: () =>
            import(
              /* webpackChunkName: "guestlist-rankings" */
              "./views/GuestList/Rankings.vue"
            )
        },
        // {
        //   path: "embed",
        //   name: "guestlist-embed",
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "guestlist-embed" */
        //       "./views/GuestList/Embed.vue"
        //     )
        // },
        {
          path: "list-settings",
          name: "guestlist-settings",
          component: () =>
            import(
              /* webpackChunkName: "guestlist-rankings" */
              "./views/GuestList/ListSettings.vue"
            )
        }
      ]
    },
    {
      path: "/promoters/:url",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "Promoter-dashboard" */
          "./views/PromoterDashboard.vue"
        ),
      children: [
        {
          path: "",
          name: "promoter-dashboard",
          redirect: "manage"
        },
        {
          path: "manage",
          component: () =>
            import(
              /* webpackChunkName: "Promoter-dashboard" */
              "./views/PromoterDashboard/Manage.vue"
            )
        },
        {
          path: "commissions",
          name: "promoter-commissions",
          component: () =>
            import(
              /* webpackChunkName: "Promoter-dashboard" */
              "./views/PromoterDashboard/Commissions.vue"
            )
        },
        {
          path: "financials",
          name: "promoter-financials",
          component: () =>
            import(
              /* webpackChunkName: "Promoter-dashboard" */
              "./views/PromoterDashboard/Financials.vue"
            )
        },
        {
          path: "rankings",
          name: "promoter-rankings",
          component: () =>
            import(
              /* webpackChunkName: "Promoter-dashboard" */
              "./views/PromoterDashboard/Rankings.vue"
            )
        }
      ]
    },
    {
      path: "/brands",
      name: "brands",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "brand-dashboard" */
          "./views/Brands/Index.vue"
        ),
      children: [
        {
          path: "",
          name: "my-brand",
          redirect: "my-brand"
        },
        {
          path: "my-brand",
          component: () =>
            import(
              /* webpackChunkName: "brand-dashboard" */ "./views/Brands/MyBrand.vue"
            )
        },
        {
          path: "additional-brand",
          component: () =>
            import(
              /* webpackChunkName: "brand-dashboard" */ "./views/Brands/AdditionalBrand.vue"
            )
        }
      ]
    },

    {
      path: "/brands/add-brand",
      /* webpackChunkName: "add-brand" */

      component: () => import("./views/Brands/AddBrand.vue"),
      children: [
        {
          path: "",
          name: "add-brand",
          redirect: "general-info"
        },
        {
          path: "general-info",
          name: "general-info",
          component: () =>
            import(
              /* webpackChunkName: "general-info" */ "./components/company/GeneralInfo.vue"
            )
        }
      ]
    },
    {
      path: "/brands/payout/:subdomain",
      name: "payout",
      component: () =>
        import(/* webpackChunkName: "payout" */ "./views/Brands/Payout.vue")
    },
    {
      path: "/brands/edit-brand/:subdomain",
      name: "edit-brand",
      component: () =>
        import(/* webpackChunkName: "brand-info" */ "./views/Brands/Info.vue")
    },
    {
      path: "/brands/buyers-list/:subdomain",
      name: "order-list",
      component: () =>
        import(
          /* webpackChunkName: "brand-info" */ "./views/Brands/BuyersList.vue"
        )
    },
    {
      path: "/brands/stats/:subdomain",
      name: "brand-stats",
      component: () =>
        import(/* webpackChunkName: "stats" */ "./views/Brands/Stats.vue")
    },
    {
      path: "/brands/user-permissions/:subdomain",
      name: "user-permissions",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "brand-user-permissions" */ "./views/Brands/UserPermissions.vue"
        ),
      children: [
        {
          path: "",
          name: "add-user",
          redirect: "add-user"
        },
        {
          path: "add-user",
          component: () =>
            import(
              /* webpackChunkName: "brand-user-permissions" */ "./views/Brands/UserPermission/AddUser.vue"
            )
        },
        {
          path: "manage-user",
          component: () =>
            import(
              /* webpackChunkName: "brand-user-permissions" */ "./views/Brands/UserPermission/ManageUser.vue"
            )
        }
      ]
    },
    {
      path: "/brands/taxes/:subdomain",
      name: "brand-taxes",
      component: () =>
        import(/* webpackChunkName: "brand-taxes" */ "./views/Brands/Taxes.vue")
    },
    {
      path: "/brands/embed/:subdomain",
      name: "brand-embed",
      component: () =>
        import(/* webpackChunkName: "brand-taxes" */ "./views/Brands/Embed.vue")
    },
    {
      path: "/brands/additional-brand/:subdomain",
      name: "additional-brand",
      component: () =>
        import(
          /* webpackChunkName: "brand-taxes" */ "./views/Brands/AdditionalBrand.vue"
        )
    },
    {
      path: "/brands/subscriptions/:subdomain",
      component: () =>
        import(
          /* webpackChunkName: "subscription" */ "./views/Brands/Subscription/Index.vue"
        ),
      children: [
        {
          path: "",
          redirect: "pricing-plan"
        },
        {
          path: "pricing-plan",
          component: () =>
            import(
              /* webpackChunkName: "subscription" */ "./views/Brands/Subscription/PricingPlan.vue"
            )
        },
        {
          path: "active",
          component: () =>
            import(
              /* webpackChunkName: "active" */
              "./views/Brands/Subscription/Active.vue"
            )
        },
        {
          path: "change-plan",
          component: () =>
            import(
              /* webpackChunkName: "change-plan" */
              "./views/Brands/Subscription/ChangePlan.vue"
            )
        }
      ]
    },
    {
      path: "/emails/:url",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "Emails" */
          "./views/Emails.vue"
        ),
      children: [
        {
          path: "",
          name: "emails",
          redirect: "instant-email"
        },
        {
          path: "instant-email",
          component: () =>
            import(
              /* webpackChunkName: "Emails" */
              "./views/Emails/InstantEmail.vue"
            )
        },
        {
          path: "automation",
          component: () =>
            import(
              /* webpackChunkName: "Emails" */
              "./views/Emails/Automation.vue"
            )
        },
        {
          path: "custom-emails",
          component: () =>
            import(
              /* webpackChunkName: "CustomEmails" */
              "./views/Emails/CustomEmails.vue"
            )
        },
        {
          path: "lists",
          component: () =>
            import(
              /* webpackChunkName: "Emails" */
              "./views/Emails/ImportLists.vue"
            )
        },
        {
          path: "errors",
          component: () =>
            import(
              /* webpackChunkName: "Emails" */
              "./views/Emails/Errors.vue"
            )
        }
      ]
    },
    {
      path: "/dashboard",
      name: "dashboard",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "event-dashboard" */
          "./views/Events/Index.vue"
        ),
      children: [
        {
          path: "",
          name: "event",
          redirect: "current-events"
        },
        {
          path: "current-events",
          component: () =>
            import(
              /* webpackChunkName: "create-event" */ "./views/Events/CurrentEvents.vue"
            )
        },
        {
          path: "previous-events",
          component: () =>
            import(
              /* webpackChunkName: "create-event" */ "./views/Events/PreviousEvents.vue"
            )
        }
      ]
    },
    {
      path: "/ticket-setup/:url",
      name: "ticket-setup",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ticket-setup" */
          "./views/TicketSetup.vue"
        )
    },
    {
      path: "/event-analytics/:url",
      name: "event-analytics",
      component: () =>
        import(
          /* webpackChunkName: "event-analytics" */
          "./views/EventAnalytics.vue"
        )
    },
    {
      path: "/event-tickets-list/:url",
      name: "event-tickets-list",
      component: () =>
        import(
          /* webpackChunkName: "ticket-buyers" */
          "./views/EventTicketsList.vue"
        )
    },
    {
      path: "/automated-surveys",
      name: "survey",
      component: () =>
        import(
          /* webpackChunkName: "automated-serveys" */
          "./views/AutomatedSurveys.vue"
        )
    },
    {
      path: "/embeds/:url",
      name: "embeds",
      props: true,
      component: () =>
        import(/* webpackChunkName: "embeds" */ "./views/Embeds.vue")
    },
    {
      path: "/fields/:url",
      name: "custom-fields",
      props: true,
      component: () =>
        import(/* webpackChunkName: "fields" */ "./views/CustomFields.vue")
    },
    {
      path: "/waivers/:url",
      name: "waivers",
      props: true,
      component: () =>
        import(/* webpackChunkName: "waivers" */ "./views/Events/Waivers.vue")
    },
    // {
    //   path: "/reserved-seats/:url",
    //   name: "reserved-seats",
    //   props: true,
    //   component: () =>
    //       import(/* webpackChunkName: "waivers" */ "./views/Events/ReservedSeats.vue")
    // },
    // {
    //   path: "/user-permissions",
    //   name: "user-permissions",
    //   component: () =>
    //     import(/* webpackChunkName: "embeds" */ "./views/UserPermissions.vue")
    // },
    {
      path: "/covid-relief",
      name: "covid-relief",
      props: true,
      meta: { allowAnonymous: true },
      component: () =>
        import(/* webpackChunkName: "embeds" */ "./views/CovidRelief.vue")
    },
    {
      path: "/notifications",
      name: "notifications",
      component: () =>
        import(
          /* webpackChunkName: "notifications" */ "@/views/Notifications.vue"
        )
    },
    {
      path: "/verify",
      name: "verify",
      meta: { allowAnonymous: true },
      component: () =>
        import(/* webpackChunkName: "verify" */ "@/views/Verify.vue")
    },
    // keep this one at the bottom
    {
      path: "*",
      name: "not-found",
      component: () =>
        import(/* webpackChunkName: "404" */ "@/views/NotFound.vue")
    }
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  }
});

router.beforeEach((to, from, next) => {
  function stepperRoute() {
    // let steps = store.getters.getStepperProperties();
    // if (!steps) {
    //   store.dispatch("loadUser");
    //   store.watch(store.getters.getStepperProperties, function() {
    //     if (store.getters.getStepperProperties()) {
    //       proceed();
    //     }
    //   });
    // } else {
    proceed();
    // }
  }

  function proceed() {
    let steps = store.state.user;

    if (steps) {
      if (steps.plannerAddedBrands > 0 && to.name !== "general-info") {
        next();
        return;
      }

      if (
        !steps.coordVerified &&
        to.name !== "identity-protection" &&
        !to.meta.allowAnonymous
      ) {
        next("/id-protection");
      } else {
        next();
      }

      if (
        steps.plannerBrands < 1 &&
        !to.meta.allowAnonymous &&
        to.name !== "add-brand" &&
        steps.coordVerified
      ) {
        next("/brands/add-brand");
      } else {
        next();
      }

      if (
        steps.plannerPage < 1 &&
        !to.meta.allowAnonymous &&
        steps.hasOwnProperty("activeBrand") &&
        steps.plannerBrands > 0 &&
        to.name !== "brand-page"
      ) {
        let subdomain = steps.activeBrand.subdomain;
        next(`/brand-page/${subdomain}`);
      } else {
        next();
      }

      if (
        steps.plannerPayouts < 1 &&
        !to.meta.allowAnonymous &&
        steps.hasOwnProperty("activeBrand") &&
        steps.plannerPage > 0 &&
        to.name !== "payout"
      ) {
        let subdomain = steps.activeBrand.subdomain;
        next(`/brands/payout/${subdomain}`);
      } else {
        next();
      }
    } else next();
  }

  try {
    if (!to.meta.allowAnonymous && !isAuthenticated()) {
      next({
        path: "/",
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      if (store.state.user.email) {
        proceed();
      } else {
        store.watch(
          () => store.getters.getUser(),
          function() {
            if (store.getters.getStepperProperties()) {
              proceed();
            }
          }
        );
        next();
      }
    }
  } catch (err) {
    console.log(err);
  }
});

router.afterEach((to, from) => {
  const toParts = to.path.split("/");
  const fromParts = from.path.split("/");

  // this handles page with child paths, not 100% sure this is fool proof
  if (
    toParts.length > 1 &&
    fromParts.length > 1 &&
    toParts[1] === fromParts[1]
  ) {
    return;
  }

  // reset navbar meta when navigating
  store.commit("setTitle", "");
  // subtitle is handled by active brand state
  if (store.state.activeBrand) {
    store.commit("setSubtitle", store.state.activeBrand.name);
  }
  store.commit("setHelpVideoSrc", "");
});

export default router;
