<template>
  <div>
    <multiselect
      v-model="value"
      :options="options"
      placeholder="Select one"
      label="label"
      selectLabel=""
      :closeOnSelect="true"
      :preserveSearch="true"
      @search-change="emitInput"
      :disabled="context.attributes.disabled"
    >
      <span slot="noResult"
        >Oops! No elements found. Consider changing the search query.</span
      >
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "TTSelectSearch",
  components: {
    Multiselect,
  },
  props: {
    context: Object,
  },
  computed: {
    options() {
      return this.context.options || [];
    },
    value: {
      get() {
        if (this.context.model.label) {
          return {
            label: this.context.model.label,
            value: this.context.model.value,
          };
        } else {
          return { label: this.context.model, value: this.context.model };
        }
      },
      set(input) {
        // this.$emit("input", input.value);
        if (this.context.attributes.currency) {
          this.context.model = { label: input.label, value: input.value };
        } else {
          this.context.model = input.value;
        }
      },
    },
    placeholder() {
      return this.context.attributes.placeholder;
    },
  },
  methods: {
    emitInput(input) {
      this.$emit("searchChange", input);
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="less" scoped>
::v-deep {
  .multiselect--disabled {
    background: none;
    opacity: 0.3;

    .multiselect__select {
      background: none;
    }
  }
  .multiselect--active {
    z-index: 5;
  }
  .multiselect__input,
  .multiselect__single {
    background: var(--input-background);
    color: #fff;
    margin-bottom: 0;
    padding: 0;
    line-height: initial;
  }

  .multiselect__single {
    background: var(--input-background);
    color: #fff;
    margin-bottom: 0;
    padding: 0;
  }

  .multiselect__content {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 0;
    min-width: 100%;
    vertical-align: top;
    background-image: var(--options-background);
    color: #fff;
    z-index: 15;
  }

  .multiselect__select {
    min-height: 40px;
    padding-top: 12px;
  }

  .multiselect__placeholder {
    font-size: 16px;
    margin-bottom: 0;
    color: var(--text);
  }
  .multiselect__tags {
    min-height: 40px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    padding: 12px 40px 12px 12px;
    border-radius: 8px !important;
    border: 1px solid var(--input-border);
    background: var(--input-background);
    font-size: 14px;
  }

  .multiselect__tag {
    margin-bottom: initial;
  }

  .multiselect__option {
    background: none !important;
    border-bottom: 1px solid var(--input-border);
    line-height: initial;
    transition: 0.4s;
    &:hover {
      // color: var(--text-secondary);
      padding-left: 16px;
      padding-right: 12px;
    }

    &--selected {
      color: var(--primary);

      &.multiselect__option--highlight {
        color: var(--primary);
      }
    }
  }

  .multiselect__content-wrapper {
    border: 1px solid var(--input-border);
    border-radius: 5px;
  }

  .multiselect__option--group {
    color: var(--primary);
    font-weight: bold;
    &:hover {
      color: var(--text-secondary);
    }
  }

  .multiselect__option--selected.multiselect__option--highlight:after {
    background: var(--input-error-focus);
  }
  .multiselect__option--group-selected.multiselect__option--highlight:after {
    background: var(--input-error-focus);
  }
}
</style>
