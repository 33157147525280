import Card from "@/components/Card.vue";
import ClientOnly from "@/components/ClientOnly.vue";
import { convert } from "@/helpers/currencyConversion";
import { LoaderManager } from "@/helpers/loader";
import { NotificationManager } from "@/helpers/notifications";
import "@/styles/_formulate.less";
import VueFormulate from "@braid/vue-formulate";
import * as Sentry from "@sentry/vue";
import Axios from "axios";
import dayjs from "dayjs";
import VCalendar from "v-calendar";
import vGoogleTranslate from "v-google-translate";
import VMask from "v-mask";
import Vue from "vue";

import VueCookies from "vue-cookies";
import VueMeta from "vue-meta";
import VueQuillEditor from "vue-quill-editor";
import VueToastification, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

import * as VueGoogleMaps from "vue2-google-maps";
import App from "./App.vue";
import "./plugins/froala";
import "./plugins/vue-pdf";
import vuetify from "./plugins/vuetify";

import "./registerServiceWorker";
import router from "./router";
import store from "./store/store.js";
// New CRM

Vue.config.productionTip = false;
Vue.prototype.$axios = Axios;
Vue.prototype.$bus = new Vue();
Vue.prototype.$loader = new LoaderManager(store);
Vue.prototype.$notifications = new NotificationManager();

Vue.use(vGoogleTranslate);
Vue.component("vue-cookies", VueCookies);

Axios.defaults.baseURL = process.env.VUE_APP_SERVICE_URL.slice(0, -1);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBcexezrA_GnkA1uAOFi4KNpQGSzbP7nbM",
    libraries: "places"
  }
});
// Vue.use(QuasarTiptapPlugin, {
//   spellcheck: true,
// });
Vue.use(VueMeta, {
  keyName: "head"
});

Vue.use(VMask);
Vue.use(VCalendar);
Vue.use(VueToastification, {
  position: POSITION.BOTTOM_LEFT,
  maxToasts: 3,
  newestOnTop: true
});
Vue.use(VueFormulate, require("@/formulate.config.js").default);
Vue.use(VueQuillEditor);

Vue.component("Card", Card);
Vue.component("ClientOnly", ClientOnly);

Sentry.init({
  Vue,
  dsn:
    "https://727a9338cbdf4ba7ab2121582c271222@o1081988.ingest.sentry.io/6090000",
  environment: process.env.VUE_APP_ENVIRONMENT,
  // integrations: [
  //   new Integrations.BrowserTracing({
  //     routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  //     tracingOrigins: ["localhost", "planners.incredevent.com", "planners.staging.incredevent.com", /^\//],
  //   }),
  // ],
  tracesSampleRate: 0.25,
  logErrors: true,
  enabled: process.env.NODE_ENV !== "development"
});

Vue.mixin({
  methods: {
    formatDate(date, format = "ddd, MMM DD, YYYY") {
      if (date.$date) {
        date = +date.$date.$numberLong;
      } else {
        date = +date;
      }

      return dayjs(date).format(format);
    },
    formatMoney(money, eventCurrencyCode = "cad") {
      const cadOption = this.$store.state.cadCurrencyOption;
      const usdOption = this.$store.state.usdCurrencyOption;
      const currentStoreCurrency = this.$store.state.currency;

      if (cadOption === null && usdOption === null) {
        return;
      }
      let currency = null;

      if (eventCurrencyCode === "usd") {
        currency = usdOption.find(x => x.label === currentStoreCurrency.name);
      } else {
        currency = cadOption.find(x => x.label === currentStoreCurrency.name);
      }

      return convert(currency.value, parseFloat(money), currency.label);
    },
    createBuyersLink(link, whitelabel = null) {
      if (whitelabel) {
        switch (process.env.VUE_APP_ENVIRONMENT) {
          case "development":
            return `${whitelabel.domainName}/${link}`;
          case "staging":
            return `${whitelabel.domainName}/${link}`;
          default:
            return `${whitelabel.domainName}/${link}`;
        }
      } else {
        switch (process.env.VUE_APP_ENVIRONMENT) {
          case "development":
            return `http://localhost:3100/${link}`;
          case "staging":
            return `https://staging.incredevent.com/${link}`;
          default:
            return `https://incredevent.com/${link}`;
        }
      }
    }
  }
});

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
