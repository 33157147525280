import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = () => {
  return {
    guestlist_cart: [],
    guestlist: {
      event: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      estArrTime: "",
      occasion: "",
      additionalData: "",
      malePerson: 0,
      femalePerson: 0,
      nonSpecificPerson: 0,
      section: "",
      pv: null
    }
  };
};
const getters = {
  getCart(state) {
    return state.guestlist_cart;
  }
};

const mutations = {
  addToCart(state, product) {
    state.guestlist_cart.push(product);
  },
  removeFromCart(state, { id }) {
    const index = state.guestlist_cart.findIndex(item => item.id === id);
    if (index !== -1) {
      state.guestlist_cart.splice(index, 1);
    }
  },
  updateCart(state, { id, quantity, type, price, event_url }) {
    const cartItem = state.guestlist_cart.find(item => item.id === id);
    if (cartItem) {
      cartItem.quantity = quantity;
    } else {
      state.guestlist_cart.push({ id, quantity, type, price, event_url });
    }
  },
  setCart(state, items) {
    state.guestlist_cart = items;
  },
  updateGuestlist(state, guestlist) {
    state.guestlist = guestlist;
  },
  resetGuestlist(state) {
    state.guestlist = {
      event: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      estArrTime: "",
      occasion: "",
      additionalData: "",
      malePerson: 0,
      femalePerson: 0,
      nonSpecificPerson: 0,
      section: "",
      pv: null
    };
  }
};
const actions = {
  async updateCart({ commit }, { id, quantity, type, price, event_url }) {
    // try {
    //   const response = await axios.post("/cart/update", {
    //     guestlist: { id: id, quantity: quantity ,type: type }
    //   });
    commit("updateCart", { id, quantity, type, price, event_url });
    //   return await Promise.resolve();
    // } catch (error) {
    //   return await Promise.reject(error);
    // }
  },
  setCart({ commit }, items) {
    commit("setCart", items);
  },
  removeFromCart({ commit }, { id }) {
    commit("removeFromCart", { id });
  },
  async updateGuestlist({ commit }, guestlist) {
    commit("updateGuestlist", guestlist);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
